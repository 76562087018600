const getNormalizedAmountFromCents = (amount: number) => {
  if (amount < 10) return `0,0${amount}`;
  if (amount < 100) return `0,${amount}`;

  const beforeCommaAmountValue = amount.toString().slice(0, -2);
  const afterCommaAmountValue = amount.toString().slice(-2);

  return `${beforeCommaAmountValue},${afterCommaAmountValue}`;
};

export default getNormalizedAmountFromCents;
