import React from 'react';
import {
  Paper, Table, TableContainer,
} from '@mui/material';
import PaymentsTableBody from '../payments-table-body/payments-table-body';
import { Payment } from '../../../../../../models';
import { SortDirection } from '../../../../../../interfaces';
import { TableBodySkeleton, TableHeader, TablePagination } from '../../../../../../shared/components';
import paymentsTableService from '../../services/payments-table-service';
import useTableContext from '../../../../../../context/table/context';

interface Props {
  payments: Payment[];
  loadingPayments: boolean;
  onSelectPayment: (payment: Payment) => void;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
  orderByKey: keyof Payment;
  setOrderByKey: (orderByKey: keyof Payment) => void;
}

export default function PaymentsTable({
  payments, loadingPayments, onSelectPayment, sortDirection, orderByKey, setSortDirection, setOrderByKey,
}: Props) {
  const [page, setPage] = React.useState(0);

  const { defaultRowsPerPage } = useTableContext();
  const effectiveRowsPerPage = defaultRowsPerPage - 1;

  return (
    <Paper>
      <TableContainer style={{ tableLayout: 'fixed' }}>
        <Table
          classes={{ root: 'max-w-full whitespace-nowrap' }}
          size="medium"
        >
          <TableHeader<Payment>
            headerCells={paymentsTableService.headerCells}
            sortDirection={sortDirection}
            orderByKey={orderByKey}
            setOrderByKey={setOrderByKey}
            setSortDirection={setSortDirection}
          />

          {!loadingPayments && (
            <PaymentsTableBody
              payments={payments}
              page={page}
              onSelectPayment={onSelectPayment}
            />
          )}

          {loadingPayments && (
            <TableBodySkeleton
              rowsPerPage={effectiveRowsPerPage}
              columnsAmount={paymentsTableService.columnsAmount}
            />
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPage={effectiveRowsPerPage}
        recordsAmount={payments.length}
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}
