import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { Payment } from '../../../../../../models';
import useTableContext from '../../../../../../context/table/context';
import tableHelper from '../../../../../../shared/utils/table-helper';
import DeadlineDate from '../../../../../trials/components/deadline-date/deadline-date';
import { EmptyRows } from '../../../../../../shared/components';
import getPaymentMethodLabel from '../../../../../../shared/utils/payment/get-payment-method-label';
import paymentsTableService from '../../services/payments-table-service';
import getPaymentTrailsIdsLabel from '../../../../../../shared/utils/payment/get-payment-trails-ids-label';
import PaymentStatusLabel from '../../../payment-status-label/payment-status-label';
import getPriceTagFromAmount from '../../../../../../shared/utils/payment/get-price-tag-from-amount';
import paymentTrialsInputFormService from '../../../trials-tab/services/payment-trials-input-form-service';

interface Props {
  payments: Payment[];
  page: number,
  onSelectPayment: (payment: Payment) => void,
}

export default function PaymentsTableBody({
  payments, page, onSelectPayment,
}: Props) {
  const { defaultRowsPerPage } = useTableContext();
  const effectiveRowsPerPage = defaultRowsPerPage - 1;

  return (
    <TableBody>
      {tableHelper.getTablePageRecords(payments, page, effectiveRowsPerPage)
        .map((payment, index) => {
          const labelId = `payments-table-${index}`;

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={payment.paymentId}
              sx={{
                backgroundColor: payment.pastDue ? 'rgba(255, 0, 0, 0.05)' : 'inherit',
              }}
              onClick={() => { onSelectPayment(payment); }}
            >
              <TableCell id={labelId} align="center">
                {payment.paymentId}
              </TableCell>
              <TableCell align="center">
                {payment.company.name}
              </TableCell>
              <TableCell align="center">
                <DeadlineDate deadline={payment.createdAt} isLate={false} />
              </TableCell>
              <TableCell align="center">
                <DeadlineDate deadline={payment.dueDate} isLate={payment.pastDue} />
              </TableCell>
              <TableCell align="center">
                {payment.paymentMethod && getPaymentMethodLabel(payment.paymentMethod)}
              </TableCell>
              <TableCell align="center">
                {payment.paymentTrials && getPaymentTrailsIdsLabel(payment.paymentTrials)}
              </TableCell>
              <TableCell align="center">
                {payment.paymentTrials && getPriceTagFromAmount(paymentTrialsInputFormService.getTotalAmountFromPaymentTrials(payment.paymentTrials))}
              </TableCell>
              <TableCell align="center">
                <PaymentStatusLabel status={payment.status} />
              </TableCell>
            </TableRow>
          );
        })}

      <EmptyRows
        page={page}
        recordsAmount={payments.length}
        columnsAmount={paymentsTableService.columnsAmount}
        rowsPerPage={effectiveRowsPerPage}
      />
    </TableBody>
  );
}
