import React from 'react';
import { CreateExpenseAttributes } from '../../../../interfaces';
import { Modal } from '../../../../shared/components';
import CreateExpenseForm from '../create-expense-form/create-expense-form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  createExpense: (createExpenseAttributes: CreateExpenseAttributes) => void;
}

export default function ExpenseModal(
  {
    isOpen, onClose, createExpense,
  }: Props,
) {
  return (
    <Modal title="Nova Despesa" isOpen={isOpen} onClose={onClose}>
      <CreateExpenseForm createExpense={createExpense} />
    </Modal>
  );
}
