enum TaskType {
  JUDICIAL_ADMINISTRATION = 'JUDICIAL_ADMINISTRATION',
  CALCULATION_ADJUSTMENT_3 = 'CALCULATION_ADJUSTMENT_3',
  GR_CALCULATION_ADJUSTMENT = 'GR_CALCULATION_ADJUSTMENT',
  FINANCIAL_ANALYSIS = 'FINANCIAL_ANALYSIS',
  TCD_ANALYSIS = 'TCD_ANALYSIS',
  WORKING_HOURS_ACCOUNTING = 'WORKING_HOURS_ACCOUNTING',
  UPDATE = 'UPDATE',
  UPDATE_AND_OPINION = 'UPDATE_AND_OPINION',
  REAL_ESTATE_EVALUATION = 'REAL_ESTATE_EVALUATION',
  CALCULUS2500 = 'CALCULUS2500',
  CALCULATIONS_AND_OPINION = 'CALCULATIONS_AND_OPINION',
  CALCULATIONS_AND_QUESTION = 'CALCULATIONS_AND_QUESTION',
  ACTUARIAL_CALCULATION = 'ACTUARIAL_CALCULATION',
  CHEQUE_SPECIAL_CALCULATIONS = 'CHEQUE_SPECIAL_CALCULATIONS',
  COMPENSATION_CALCULATIONS = 'COMPENSATION_CALCULATIONS',
  FGTS_CALCULATIONS = 'FGTS_CALCULATIONS',
  LOAS_CALCULATIONS = 'LOAS_CALCULATIONS',
  RESCISSION_CALCULATIONS = 'RESCISSION_CALCULATIONS',
  CALCULATIONS_AND_IMPUGNATION = 'CALCULATIONS_AND_IMPUGNATION',
  AGREEMENT_CALCULATIONS = 'AGREEMENT_CALCULATIONS',
  LIQUIDATION_CALCULATIONS = 'LIQUIDATION_CALCULATIONS',
  REDUCED_LIQUIDATION_CALCULATIONS = 'REDUCED_LIQUIDATION_CALCULATIONS',
  INSS_AND_IR_CALCULATIONS = 'INSS_AND_IR_CALCULATIONS',
  INSTRUCTION_CALCULATIONS = 'INSTRUCTION_CALCULATIONS',
  CALCULATIONS_CONFERENCE = 'CALCULATIONS_CONFERENCE',
  JUDGMENT_CONTINGENCY = 'JUDGMENT_CONTINGENCY',
  INITIAL_CONTINGENCY = 'INITIAL_CONTINGENCY',
  SENTENCE_CONTINGENCY = 'SENTENCE_CONTINGENCY',
  DILIGENCE = 'DILIGENCE',
  CLARIFICATION = 'CLARIFICATION',
  INFLATIONARY_PURGE = 'INFLATIONARY_PURGE',
  IMPUGNATION = 'IMPUGNATION',
  PERITIAL_HONORARIUM_IMPUGNATION = 'PERITIAL_HONORARIUM_IMPUGNATION',
  REAL_ESTATE_IMPUGNATION = 'REAL_ESTATE_IMPUGNATION',
  CALCULATION_ENV_BAS_INFO = 'CALCULATION_ENV_BAS_INFO',
  EVENT2500AND2501 = 'EVENT2500AND2501',
  TECHNICAL_OPINION = 'TECHNICAL_OPINION',
  INSALUBRITY_EXPERTISE = 'INSALUBRITY_EXPERTISE',
  INSTRUCTION_EXPERTISE = 'INSTRUCTION_EXPERTISE',
  MEDICAL_EXPERTISE = 'MEDICAL_EXPERTISE',
  OFFICIAL_EXPERTISE = 'OFFICIAL_EXPERTISE',
  DANGEROUSNESS_EXPERTISE = 'DANGEROUSNESS_EXPERTISE',
  ACCOUNTABILITY = 'ACCOUNTABILITY',
  MEDICAL_QUESTION = 'MEDICAL_QUESTION',
  QUESTIONS = 'QUESTIONS',
  QUESTIONS_AND_IMPUGNATION = 'QUESTIONS_AND_IMPUGNATION',
  REAL_ESTATE_QUESTIONS = 'REAL_ESTATE_QUESTIONS',
  FINANCING_RECAL = 'FINANCING_RECAL',
  MANAGERIAL_REPORT = 'MANAGERIAL_REPORT',
  DOCUMENTATION_REQUEST = 'DOCUMENTATION_REQUEST',
  DILIGENCE_TERM = 'DILIGENCE_TERM',
  IMPUGNATION_VIEW = 'IMPUGNATION_VIEW',
  OTHERS = 'OTHERS',
}

export default TaskType;
