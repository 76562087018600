import React from 'react';
import { Button, Typography } from '@mui/material';
import { Modal } from '../../../../../shared/components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onReceiptDownload: (showDueDate: boolean) => void;
}

export default function DownloadReceiptDocumentTypeModal(
  {
    isOpen, onClose, onReceiptDownload,
  }: Props,
) {
  const handleReceiptDownload = (showDueDate: boolean) => {
    onClose();
    onReceiptDownload(showDueDate);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={400}
    >
      <>
        <div className="flex flex-col justify-center mb-10">
          <Typography variant="body1" align="center">Deseja exibir os prazos de pagamento?</Typography>
        </div>

        <div className="w-full flex justify-center">
          <div className="w-6/12 flex flex-row justify-between">
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => handleReceiptDownload(true)}
            >
              Sim
            </Button>

            <Button
              color="secondary"
              variant="outlined"
              onClick={() => handleReceiptDownload(false)}
            >
              Não
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
}
