import React, { useState } from 'react';
import { DeletePaymentAttributes, UpdatePaymentAttributes } from '../../../../interfaces';
import { Payment } from '../../../../models';
import { Modal } from '../../../../shared/components';
import { PaymentStatus } from '../../../../shared/enums';
import PaymentDetailsModalContent from '../payment-details-modal-content/payment-details-modal-content';
import UpdatePaymentModalContent from '../update-payment-modal-content/update-payment-modal-content';
import ViewPaymentModalTitle from '../view-payment-modal-title/view-payment-modal-title';

interface Props {
  payment: Payment;
  isOpen: boolean;
  onClose: () => void;
  updatePaymentStatus: (paymentStatus: PaymentStatus) => void;
  updatePayment: (updatePaymentAttributes: UpdatePaymentAttributes) => void;
  deletePayment: (deletePaymentAttributes: DeletePaymentAttributes) => void;
}

export default function ViewPaymentModal(
  {
    payment, isOpen, onClose, updatePaymentStatus, updatePayment, deletePayment,
  }: Props,
) {
  const [isContentUpdate, setIsContentUpdate] = useState<boolean>(false);

  return (
    <Modal
      title={<ViewPaymentModalTitle payment={payment} onUpdatePayment={() => setIsContentUpdate(!isContentUpdate)} />}
      isOpen={isOpen}
      onClose={onClose}
    >
      <>
        {!isContentUpdate && (
          <PaymentDetailsModalContent
            payment={payment}
            updatePaymentStatus={updatePaymentStatus}
          />
        )}

        {isContentUpdate && (
          <UpdatePaymentModalContent
            payment={payment}
            updatePayment={updatePayment}
            onClose={onClose}
            deletePayment={deletePayment}
          />
        )}
      </>
    </Modal>
  );
}
