import { HeaderCell, TrialOrderableKeys } from '../../../../../interfaces';
import { Trial } from '../../../../../models';
import TrialOrderableColumn from '../../../../../shared/enums/trial-orderable-colum';

const headerCells: HeaderCell<Trial>[] = [
  {
    key: 'trialId',
    label: 'ID',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'responsible',
    label: 'Responsável',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'createdAt',
    label: 'Criação',
    sortable: false,
    width: 'auto',
  },
  {
    key: 'deadline',
    label: 'Prazo',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'billingStatus',
    label: 'Status de Cobrança',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'status',
    label: 'Status do Processo',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'taskType',
    label: 'Serviço',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'trialNumber',
    label: 'Processo',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'plaintiff',
    label: 'Autor',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'office',
    label: 'Escritório',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'lawyer',
    label: 'Advogado',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'client',
    label: 'Cliente',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'region',
    label: 'Região',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'trialType',
    label: 'Tipo do Processo',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'side',
    label: 'Parte',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'defendant',
    label: 'Réu',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'privateAnnotations',
    label: 'Descrição Privada',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'publicAnnotations',
    label: 'Descrição Pública',
    sortable: true,
    width: 'auto',
  },
];

const getTrialOrderableColumnFromTrialKey = (key: keyof TrialOrderableKeys) => {
  const trialsOrderableColumnsByTrialKeys: Record<keyof TrialOrderableKeys, TrialOrderableColumn> = {
    trialId: TrialOrderableColumn.TRIAL_ID,
    client: TrialOrderableColumn.CLIENT,
    office: TrialOrderableColumn.OFFICE,
    responsible: TrialOrderableColumn.RESPONSIBLE,
    status: TrialOrderableColumn.STATUS,
    billingStatus: TrialOrderableColumn.BILLING_STATUS,
    lawyer: TrialOrderableColumn.LAWYER,
    email: TrialOrderableColumn.EMAIL,
    taskType: TrialOrderableColumn.TASK_TYPE,
    trialNumber: TrialOrderableColumn.TRIAL_NUMBER,
    deadline: TrialOrderableColumn.DEADLINE,
    side: TrialOrderableColumn.SIDE,
    plaintiff: TrialOrderableColumn.PLAINTIFF,
    defendant: TrialOrderableColumn.DEFENDANT,
    amount: TrialOrderableColumn.AMOUNT,
    privateAnnotations: TrialOrderableColumn.PRIVATE_ANNOTATIONS,
    publicAnnotations: TrialOrderableColumn.PUBLIC_ANNOTATIONS,
  };

  return trialsOrderableColumnsByTrialKeys[key];
};

const columnsAmount = headerCells.length;

const trialsTableService = {
  headerCells,
  columnsAmount,
  getTrialOrderableColumnFromTrialKey,
};

export default trialsTableService;
