import React from 'react';
import { Button } from '@mui/material';
import { DelayedSearchBar } from '../../../../../../shared/components';

interface Props {
  onSearchTermChange: (searchTerm: string) => void,
  onOpenPaymentsFilterModal: () => void;
}

export default function TopLayout({
  onSearchTermChange, onOpenPaymentsFilterModal,
}: Props) {
  return (
    <div className="flex flex-row justify-end">
      <div className="flex h-full flex-row">
        <Button variant="outlined" onClick={onOpenPaymentsFilterModal}>
          Filtros
        </Button>
        <div className="ml-5"><DelayedSearchBar onSearchTermChange={onSearchTermChange} /></div>
      </div>
    </div>
  );
}
