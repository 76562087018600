import React from 'react';
import {
  IconButton, Typography,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { Payment } from '../../../../models';

interface Props {
  payment: Payment;
  onUpdatePayment: () => void;
}

export default function ViewPaymentModalTitle(
  {
    payment,
    onUpdatePayment,
  }: Props,
) {
  return (
    <div className="flex flex-row items-center">
      <div className="mr-2">
        <Typography variant="h2">
          {`Pagamento de ${payment.company.name}`}
        </Typography>
      </div>

      <div>
        <IconButton onClick={onUpdatePayment}>
          <CreateIcon color="primary" />
        </IconButton>
      </div>
    </div>
  );
}
