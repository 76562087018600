import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { PaymentsTab, TabPanel, TrialsTab } from './components';

export default function Payments() {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <div className="-mt-6">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label="PROCESSOS" id="0" />
            <Tab label="PAGAMENTOS" id="1" />
          </Tabs>
        </Box>

        <TabPanel tab={currentTab} index={0}>
          <TrialsTab />
        </TabPanel>

        <TabPanel tab={currentTab} index={1}>
          <PaymentsTab />
        </TabPanel>

      </Box>
    </div>
  );
}
