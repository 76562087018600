import { CostCenter } from '../../enums';

const costCenterMapping = {
  [CostCenter.SALARY_SHEET]: 'Folha Salarial',
  [CostCenter.MARKETING]: 'Marketing',
  [CostCenter.OFFICE_AND_MAINTENANCE]: 'Escritório e manutenção',
  [CostCenter.TAXES]: 'Tributos',
  [CostCenter.SOFTWARE_AND_INTERNET]: 'Softwares e Internet',
  [CostCenter.ACCOUNTING]: 'Contabilidade',
  [CostCenter.WITHDRAWALS]: 'Retiradas',
  [CostCenter.ENERGY_WATER_AND_PUBLIC_SERVICES]: 'Energia, Água e Serviços Públicos',
  [CostCenter.EQUIPMENT]: 'Equipamentos',
  [CostCenter.TRAVEL_AND_COMMUTING]: 'Viagens e Deslocamento',
  [CostCenter.FOOD]: 'Alimentação',
  [CostCenter.LABOR_BENEFITS]: 'Benefícios Trabalhistas',
  [CostCenter.FINANCIAL_EXPENSES]: 'Despesas Financeiras',
  [CostCenter.OTHER]: 'Outros',
};

const getCostCenterLabel = (costCenter: CostCenter) => costCenterMapping[costCenter];

export default getCostCenterLabel;
