enum PaymentMethod {
  PIX = 'PIX',
  CASH = 'CASH',
  TICKET = 'TICKET',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
  BANK_CHECK = 'BANK_CHECK',
}

export default PaymentMethod;
