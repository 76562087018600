import { PaymentStatus } from '../../enums';

const paymentStatusMapping = {
  [PaymentStatus.PENDING]: {
    color: 'bg-gray-100',
    text: 'Pendente',
  },
  [PaymentStatus.INVOICE_GENERATED]: {
    color: 'bg-purple-light',
    text: 'NF env.',
  },
  [PaymentStatus.REPORT_GENERATED]: {
    color: 'bg-yellow-light',
    text: 'Relatório env.',
  },
  [PaymentStatus.PAID]: {
    color: 'bg-lime-lightest',
    text: 'Pago',
  },
};

const getPaymentStatusAttributes = (paymentStatus: PaymentStatus) => paymentStatusMapping[paymentStatus];

export default getPaymentStatusAttributes;
