enum CostCenter {
  SALARY_SHEET = 'SALARY_SHEET',
  MARKETING = 'MARKETING',
  OFFICE_AND_MAINTENANCE = 'OFFICE_AND_MAINTENANCE',
  TAXES = 'TAXES',
  SOFTWARE_AND_INTERNET = 'SOFTWARE_AND_INTERNET',
  ACCOUNTING = 'ACCOUNTING',
  WITHDRAWALS = 'WITHDRAWALS',
  ENERGY_WATER_AND_PUBLIC_SERVICES = 'ENERGY_WATER_AND_PUBLIC_SERVICES',
  EQUIPMENT = 'EQUIPMENT',
  TRAVEL_AND_COMMUTING = 'TRAVEL_AND_COMMUTING',
  FOOD = 'FOOD',
  LABOR_BENEFITS = 'LABOR_BENEFITS',
  FINANCIAL_EXPENSES = 'FINANCIAL_EXPENSES',
  OTHER = 'OTHER',
}

export default CostCenter;
