import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Autocomplete, Checkbox, FormControl, FormControlLabel, TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CashFlowsFilterAttributes from '../../../../interfaces/cash-flows/cash-flows-filter-attributes';
import { Company } from '../../../../models';
import { CashFlowType, CostCenter } from '../../../../shared/enums';
import companiesService from '../../../companies/services/companies-service';
import formHelper from '../../../../shared/utils/form-helper';
import { DatePickerInput, Modal } from '../../../../shared/components';
import getCostCenterLabel from '../../../../shared/utils/cash-flow/get-cost-center-label';

interface Props {
  onCashFlowsFilterAttributesChange: (cashFlowsFilterAttributes: CashFlowsFilterAttributes) => void;
  cashFlowsFilterAttributes: CashFlowsFilterAttributes;
  isOpen: boolean;
  onClose: () => void;
}

export default function CashFlowsFilterModal(
  {
    onCashFlowsFilterAttributesChange, cashFlowsFilterAttributes, isOpen, onClose,
  }: Props,
) {
  const [companies, setCompanies] = useState<Company[]>([]);

  const {
    handleSubmit, control, setValue, watch,
  } = useForm<CashFlowsFilterAttributes>({ mode: 'onTouched', defaultValues: cashFlowsFilterAttributes });

  const companyId = watch('companyId');
  const costCenter = watch('costCenter');
  const type = watch('type');

  const fetchCompaniesData = async () => {
    const fetchedCompanies = await companiesService.getCompanies().catch(() => []);
    setCompanies(fetchedCompanies);
  };

  const handleCashFlowsFilterChange = (trialsFilterAttributes: CashFlowsFilterAttributes) => {
    onCashFlowsFilterAttributesChange(formHelper.setEmptyStringsToUndefined<CashFlowsFilterAttributes>(trialsFilterAttributes));
    onClose();
  };

  useEffect(() => {
    fetchCompaniesData();
  }, []);

  return (
    <Modal width={650} title="Filtragem de movimentações" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handleCashFlowsFilterChange)} className="flex flex-col">
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-row">
            <FormControlLabel
              control={(
                <Controller
                  control={control}
                  name="type"
                  render={({
                    field: {
                      onChange, onBlur, ref,
                    },
                  }) => (
                    <Checkbox
                      onChange={() => {
                        if (!type) {
                          onChange(CashFlowType.OUTFLOW);
                          return;
                        }

                        if (type === CashFlowType.OUTFLOW) {
                          onChange(undefined);
                          return;
                        }

                        onChange(CashFlowType.INFLOW);
                      }}
                      onBlur={onBlur}
                      checked={!type || type === CashFlowType.INFLOW}
                      ref={ref}
                    />
                  )}
                />
                )}
              label="Entradas"
            />

            <FormControlLabel
              control={(
                <Controller
                  control={control}
                  name="type"
                  render={({
                    field: {
                      onChange, onBlur, ref,
                    },
                  }) => (
                    <Checkbox
                      onChange={() => {
                        if (!type) {
                          onChange(CashFlowType.INFLOW);
                          return;
                        }

                        if (type === CashFlowType.INFLOW) {
                          onChange(undefined);
                          return;
                        }

                        onChange(CashFlowType.OUTFLOW);
                      }}
                      onBlur={onBlur}
                      checked={!type || type === CashFlowType.OUTFLOW}
                      ref={ref}
                    />
                  )}
                />
                )}
              label="Saídas"
            />
          </div>

          <div className="w-full flex flex-row justify-between mb-5">
            <div className="w-[48%]">
              <Controller
                control={control}
                name="fromTransactionDate"
                render={({
                  field: {
                    onChange,
                    value,
                  },
                }) => (
                  <DatePickerInput onChange={onChange} value={value} label="De data de movimentação" />
                )}
              />
            </div>

            <div className="w-[48%]">
              <Controller
                control={control}
                name="toTransactionDate"
                render={({
                  field: {
                    onChange,
                    value,
                  },
                }) => (
                  <DatePickerInput onChange={onChange} value={value} label="Até data de movimentação" />
                )}
              />
            </div>
          </div>

          <FormControl variant="standard" classes={{ root: 'mb-5' }} fullWidth>
            <Autocomplete
              id="cost-center-select"
              options={Object.values(CostCenter)}
              getOptionLabel={(option: CostCenter) => getCostCenterLabel(option)}
              value={costCenter}
              onChange={(_, value) => {
                setValue('costCenter', value || undefined);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="cost-center-select"
                  label="Centro de custo"
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </FormControl>

          {companies && (
            <FormControl variant="standard" classes={{ root: 'mb-5' }} fullWidth>
              <Autocomplete
                id="client-select"
                options={companies}
                getOptionLabel={(option: Company) => option.name}
                value={companies.find((company) => company.companyId === companyId)}
                onChange={(_, value) => {
                  setValue('companyId', value?.companyId || undefined);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="client-select"
                    label="Cliente"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          )}

        </div>

        <div className="mt-7">
          <LoadingButton
            variant="contained"
            type="submit"
          >
            Aplicar filtro
          </LoadingButton>
        </div>
      </form>
    </Modal>
  );
}
