import { HeaderCell } from '../../../../../interfaces';
import PaymentOrderableKeys from '../../../../../interfaces/payments/payment-orderable-keys';
import { Payment } from '../../../../../models';
import { PaymentOrderableColumn } from '../../../../../shared/enums';

const headerCells: HeaderCell<Payment>[] = [
  {
    key: 'paymentId',
    label: 'ID',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'company',
    label: 'Agente Pagador',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'createdAt',
    label: 'Data de Criação',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'dueDate',
    label: 'Prazo',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'paymentMethod',
    label: 'Meio de Pagamento',
    sortable: true,
    width: 'auto',
  },
  {
    key: 'paymentTrials',
    label: 'Processos',
    sortable: false,
    width: 'auto',
  },
  {
    key: 'paymentTrials',
    label: 'Valor',
    sortable: false,
    width: 'auto',
  },
  {
    key: 'status',
    label: 'Status da Cobrança',
    sortable: true,
    width: 'auto',
  },
];

const getPaymentsOrderableColumnFromPaymentKey = (key: keyof PaymentOrderableKeys) => {
  const paymentsOrderableColumnsByPaymentKeys: Record<keyof PaymentOrderableKeys, PaymentOrderableColumn> = {
    paymentId: PaymentOrderableColumn.PAYMENT_ID,
    company: PaymentOrderableColumn.COMPANY,
    createdAt: PaymentOrderableColumn.CREATED_AT,
    dueDate: PaymentOrderableColumn.DUE_DATE,
    paymentMethod: PaymentOrderableColumn.PAYMENT_METHOD,
    status: PaymentOrderableColumn.STATUS,
  };

  return paymentsOrderableColumnsByPaymentKeys[key];
};

const columnsAmount = headerCells.length;

const paymentsTableService = {
  headerCells,
  columnsAmount,
  getPaymentsOrderableColumnFromPaymentKey,
};

export default paymentsTableService;
