const getStandarizedAmount = (amount: number, pressedKey: string) => {
  if (pressedKey === 'Backspace') {
    if (amount < 10 || amount) return 0;

    return Number(amount.toString().slice(0, -1));
  }

  if (amount.toString().length >= 9) return amount;

  return Number(`${amount}${pressedKey}`);
};

const legalKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Backspace',
];

const amountTextFieldService = {
  getStandarizedAmount,
  legalKeys,
};

export default amountTextFieldService;
