import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { SearchBar } from '../../../../shared/components';

interface Props {
  onSearchTermChange: (searchTerm: string) => void,
  onOpenCashFlowsFilterModal: () => void;
  setIsCreateExpenselModalOpen: (isCreateTrialModalOpen: boolean) => void,
  onDownloadCashFlowsCsv: () => void;
}

export default function TopLayout({
  onSearchTermChange, onOpenCashFlowsFilterModal, setIsCreateExpenselModalOpen, onDownloadCashFlowsCsv,
}: Props) {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row">
        <div className="mr-5">
          <Button variant="contained" onClick={() => setIsCreateExpenselModalOpen(true)}>
            Nova despesa
          </Button>
        </div>
        <Button onClick={onDownloadCashFlowsCsv}>
          <DownloadIcon className="mr-1" />
          Baixar planilha
        </Button>
      </div>
      <div className="flex h-full flex-row">
        <Button variant="outlined" onClick={onOpenCashFlowsFilterModal}>
          Filtros
        </Button>
        <div className="ml-5">
          <SearchBar onSearchTermChange={onSearchTermChange} />
        </div>
      </div>
    </div>
  );
}
