import React from 'react';
import { Typography } from '@mui/material';
import { PaymentTrial } from '../../../../../models';
import getTrialTitle from '../../../../../shared/utils/trial/get-trial-title';
import getPriceTagFromAmount from '../../../../../shared/utils/payment/get-price-tag-from-amount';

interface Props {
  paymentTrial: PaymentTrial;
}

export default function TrialView(
  {
    paymentTrial,
  }: Props,
) {
  return (
    <div className="flex flex-row w-full min-h-18 items-center justify-between border-[1px] border-solid border-grey-200 rounded-lg pb-3 pt-3 pr-5 pl-5">
      <div className="flex flex-col h-full">
        <Typography variant="caption" color="text.secondary">
          {`ID: ${paymentTrial.trialId}`}
        </Typography>

        <Typography variant="body1" color="text.secondary">{getTrialTitle(paymentTrial.trial)}</Typography>
      </div>

      <Typography variant="h4">{getPriceTagFromAmount(paymentTrial.amount)}</Typography>
    </div>
  );
}
