import { PaymentMethod } from '../../enums';

const paymentMethodMapping = {
  [PaymentMethod.PIX]: 'PIX',
  [PaymentMethod.CASH]: 'Dinheiro',
  [PaymentMethod.CREDIT_CARD]: 'Cartão de Crédito',
  [PaymentMethod.DEBIT_CARD]: 'Cartão de Débito',
  [PaymentMethod.BANK_TRANSFER]: 'Transferência Bancária',
  [PaymentMethod.BANK_CHECK]: 'Cheque',
  [PaymentMethod.TICKET]: 'Boleto',
};

const getPaymentMethodLabel = (paymentMethod: PaymentMethod) => paymentMethodMapping[paymentMethod];

export default getPaymentMethodLabel;
