import queryString from 'query-string';
import axiosInstance from './config/axios';
import CreateTrialRequest from './requests/trial/create-trial-request';
import DeleteTrialRequest from './requests/trial/delete-trial-request';
import GetTrialsRequest from './requests/trial/get-trials-request';
import UpdateTrialBillingStatusRequest from './requests/trial/update-trial-billing-status-request';
import UpdateTrialRequest from './requests/trial/update-trial-request';
import CreateTrialResponse from './responses/trial/create-trial-response';
import DeleteTrialResponse from './responses/trial/delete-trial-response';
import GetTrialsResponse from './responses/trial/get-trials-response';
import UpdateTrialBillingStatusResponse from './responses/trial/update-trial-billing-status-response';
import UpdateTrialResponse from './responses/trial/update-trial-response';

const TRIAL_BASE_URL = '/trial';

const createTrial = async (request: CreateTrialRequest) => (axiosInstance.post<CreateTrialResponse>(TRIAL_BASE_URL, request));

const getTrials = async (request: GetTrialsRequest) => (axiosInstance.get<GetTrialsResponse>(TRIAL_BASE_URL, { params: request }));

const getTrialsCsv = async (request: GetTrialsRequest) => (axiosInstance.get<string>(`${TRIAL_BASE_URL}/csv`, { params: request }));

const updateTrial = async (request: UpdateTrialRequest) => (axiosInstance.patch<UpdateTrialResponse>(`${TRIAL_BASE_URL}/${request.trialId}`, request));

const deleteTrial = async (request: DeleteTrialRequest) => (axiosInstance.delete<DeleteTrialResponse>(`${TRIAL_BASE_URL}/${request.trialId}`));

const updateTrialBillingStatus = async (request: UpdateTrialBillingStatusRequest) => (axiosInstance.put<UpdateTrialBillingStatusResponse>(`${TRIAL_BASE_URL}/billing-status?
${queryString.stringify({ trialIds: request.trialIds })}`, {
  billingStatus: request.billingStatus,
}));

export default {
  createTrial,
  getTrials,
  updateTrial,
  getTrialsCsv,
  deleteTrial,
  updateTrialBillingStatus,
};
