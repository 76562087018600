import React from 'react';
import { TrialBillingStatus } from '../../../../../../shared/enums';
import getTrialBillingStatusAttributes from '../../../../../../shared/utils/trial/get-trial-billing-status-attributes';

interface Props {
  status: TrialBillingStatus;
}

export default function TrialsBillingStatusLabel({ status }: Props) {
  const trialAtributes = getTrialBillingStatusAttributes(status);

  return (
    <div className={`${trialAtributes.color} w-35 rounded-md`}>
      <p className="p-1">{trialAtributes.text}</p>
    </div>
  );
}
