import React, { useEffect, useState } from 'react';
import {
  Autocomplete, FormControl, TextField, Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Company } from '../../../../models';
import companiesService from '../../../companies/services/companies-service';
import { CreatePaymentAttributes } from '../../../../interfaces';
import { emailValidationRegex } from '../../../../shared/utils/regex';
import PaymentMethod from '../../../../shared/enums/payment-method';
import getPaymentMethodLabel from '../../../../shared/utils/payment/get-payment-method-label';

export default function BillingInputForm() {
  const {
    register, setValue, watch, formState: { errors },
  } = useFormContext<CreatePaymentAttributes>();

  const companyId = watch('companyId');
  const paymentMethod = watch('paymentMethod');

  const [companies, setCompanies] = useState<Company[]>([]);

  const fetchCompaniesData = async () => {
    const fetchedCompanies = await companiesService.getCompanies().catch(() => []);
    setCompanies(fetchedCompanies);
  };

  useEffect(() => {
    fetchCompaniesData();
  }, []);

  return (
    <div className="flex flex-col w-full">
      <Typography variant="h4" classes={{ root: 'mb-5' }}>Cobrança</Typography>

      {companies.length > 0 && (
        <FormControl variant="standard" classes={{ root: 'w-full mb-5' }} fullWidth>
          <Autocomplete
            id="company-select"
            options={companies}
            getOptionLabel={(option: Company) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                id="role-client-select"
                label="Agente Pagador"
                variant="standard"
                fullWidth
                required
              />
            )}
            value={companies.find((company) => company.companyId === companyId)}
            onChange={(_, value) => {
              if (value) {
                setValue('companyId', value.companyId);
              }
            }}
          />
        </FormControl>
      )}

      <TextField
        label="Nota Fiscal"
        variant="standard"
        fullWidth
        type="text"
        classes={{ root: 'mb-5' }}
        {...register('invoice', {
          setValueAs: (invoice) => (!invoice || invoice.length > 0 ? invoice : null),
          maxLength: 80,
        })}
      />

      <TextField
        label="Email"
        variant="standard"
        fullWidth
        type="text"
        classes={{ root: 'mb-5' }}
        error={!!errors.email}
        helperText={errors.email?.message}
        {...register('email', {
          setValueAs: (email) => (email && email.length > 0 ? email : null),
          validate: (email) => ((!email || email.length === 0) || emailValidationRegex.test(email)) || 'Email inválido',
          maxLength: 80,
        })}
      />

      <FormControl variant="standard" classes={{ root: 'w-full mb-5' }} fullWidth>
        <Autocomplete
          id="paying-method-select"
          options={Object.values(PaymentMethod)}
          getOptionLabel={(option: PaymentMethod) => getPaymentMethodLabel(option)}
          renderInput={(params) => (
            <TextField
              {...params}
              id="paying-method-select"
              label="Forma de pagamento"
              variant="standard"
              fullWidth
              required
            />
          )}
          value={paymentMethod}
          onChange={(_, value) => {
            if (value) {
              setValue('paymentMethod', value);
            }
          }}
        />
      </FormControl>
    </div>
  );
}
