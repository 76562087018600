import React from 'react';
import {
  Paper, Table, TableContainer,
} from '@mui/material';
import { SortDirection } from '../../../../../../interfaces';
import {
  CheckableTableHeader,
  TableBodySkeleton, TablePagination,
} from '../../../../../../shared/components';
import { Trial } from '../../../../../../models';
import trialsTableService from '../../services/trials-table-service';
import TrialsTableBody from '../trials-table-body/trials-table-body';
import useTableContext from '../../../../../../context/table/context';

interface Props {
  trials: Trial[];
  selectedTrials: Trial[];
  loadingTrials: boolean;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
  orderByKey: keyof Trial;
  setOrderByKey: (orderByKey: keyof Trial) => void;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTrialClick: (trial: Trial) => void;
}

export default function TrialsTable({
  trials, selectedTrials, loadingTrials, sortDirection, orderByKey, setSortDirection, setOrderByKey, handleSelectAllClick, onTrialClick,
}: Props) {
  const [page, setPage] = React.useState(0);

  const { defaultRowsPerPage } = useTableContext();
  const effectiveRowsPerPage = defaultRowsPerPage - 1;

  return (
    <div>
      <Paper>
        <TableContainer style={{ tableLayout: 'fixed' }}>
          <Table
            classes={{ root: 'max-w-full whitespace-nowrap' }}
            size="medium"
          >
            <CheckableTableHeader<Trial>
              numSelected={selectedTrials.length}
              onSelectAllClick={handleSelectAllClick}
              headerCells={trialsTableService.headerCells}
              sortDirection={sortDirection}
              orderByKey={orderByKey}
              setOrderByKey={setOrderByKey}
              setSortDirection={setSortDirection}
              rowCount={trials.length}
            />

            {!loadingTrials && (
              <TrialsTableBody
                trials={trials}
                selected={selectedTrials}
                page={page}
                onTrialClick={onTrialClick}
              />
            )}

            {loadingTrials && (
              <TableBodySkeleton
                rowsPerPage={effectiveRowsPerPage}
                columnsAmount={trialsTableService.columnsAmount}
              />
            )}
          </Table>
        </TableContainer>
        <TablePagination
          recordsAmount={trials.length}
          page={page}
          setPage={setPage}
          rowsPerPage={effectiveRowsPerPage}
        />
      </Paper>
    </div>
  );
}
