import React from 'react';
import { UpdateExpenseAttributes } from '../../../../interfaces';
import DeleteExpenseAttributes from '../../../../interfaces/cash-flows/delete-expense-attributes';
import { Expense } from '../../../../models';
import { Modal } from '../../../../shared/components';
import UpdateExpenseForm from '../update-expense-form/update-expense-form';

interface Props {
  expense: Expense;
  isOpen: boolean;
  onClose: () => void;
  updateExpense: (updateExpenseAttributes: UpdateExpenseAttributes) => void;
  deleteExpense: (deleteExpenseAttributes: DeleteExpenseAttributes) => void;
}

export default function ViewExpenseModal(
  {
    expense, isOpen, onClose, updateExpense, deleteExpense,
  }: Props,
) {
  return (
    <Modal title="Editar Despesa" isOpen={isOpen} onClose={onClose}>

      <UpdateExpenseForm expense={expense} updateExpense={updateExpense} deleteExpense={deleteExpense} onClose={onClose} />

    </Modal>
  );
}
