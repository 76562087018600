import React from 'react';
import { Button } from '@mui/material';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DownloadIcon from '@mui/icons-material/Download';
import { DelayedSearchBar } from '../../../../shared/components';

interface Props {
  onSearchTermChange: (searchTerm: string) => void;
  onOpenTrialsFilterModal: () => void;
  setIsCreateTrialModalOpen: (isCreateTrialModalOpen: boolean) => void;
  onDownloadTrialsCsv: () => void;
  undoneTrials: number;
}

export default function TopLayout({
  onSearchTermChange,
  onOpenTrialsFilterModal,
  setIsCreateTrialModalOpen,
  onDownloadTrialsCsv,
  undoneTrials,
}: Props) {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row">
        <div className="mr-5">
          <Button
            variant="contained"
            onClick={() => setIsCreateTrialModalOpen(true)}
          >
            Novo Processo
          </Button>
        </div>
        <Button onClick={onDownloadTrialsCsv}>
          <DownloadIcon className="mr-1" />
          Baixar Planilha
        </Button>
      </div>
      <div className="flex h-full flex-row">
        {undoneTrials > 0 && (
          <div className="flex items-center px-4 mx-2 rounded-full bg-white gap-1">
            <PendingActionsIcon className="font-10" color="primary" />
            <strong>{undoneTrials}</strong>
          </div>
        )}
        <Button variant="outlined" onClick={onOpenTrialsFilterModal}>
          Filtros
        </Button>
        <div className="ml-5">
          <DelayedSearchBar onSearchTermChange={onSearchTermChange} />
        </div>
      </div>
    </div>
  );
}
