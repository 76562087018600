import React from 'react';
import { CircularProgress } from '@mui/material';
import Modal from '../modal/modal';

interface Props {
  isOpen: boolean;
}

export default function LoadingModal({ isOpen }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      width={400}
      hideCloseButton
    >
      <div className="w-full flex justify-center">
        <CircularProgress size={60} />
      </div>
    </Modal>
  );
}
