import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  FormControl,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { UpdateExpenseAttributes } from '../../../../interfaces';
import { CostCenter, PaymentMethod } from '../../../../shared/enums';
import formHelper from '../../../../shared/utils/form-helper';
import { AmountTextField, DatePickerInput } from '../../../../shared/components';
import getCostCenterLabel from '../../../../shared/utils/cash-flow/get-cost-center-label';
import getPaymentMethodLabel from '../../../../shared/utils/payment/get-payment-method-label';
import { Expense } from '../../../../models';
import DeleteExpenseAttributes from '../../../../interfaces/cash-flows/delete-expense-attributes';
import DeleteExpenseModal from '../delete-expense-modal/delete-expense-modal';

interface Props {
  expense: Expense;
  updateExpense: (updateExpenseAttributes: UpdateExpenseAttributes) => void;
  deleteExpense: (deleteExpenseAttributes: DeleteExpenseAttributes) => void;
  onClose: () => void;
}

export default function UpdateExpenseForm(
  {
    expense, updateExpense, deleteExpense, onClose,
  }: Props,
) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [costCenterAttribute, setCostCenterAttribute] = useState<CostCenter | null>(expense.costCenter);
  const [paymentMethodAttribute, setPaymentMethodAttribute] = useState<PaymentMethod | null>(expense.paymentMethod);
  const [isDeleteExpenseModalOpen, setIsDeleteExpenseModalOpen] = useState<boolean>(false);

  const useFormMethods = useForm<UpdateExpenseAttributes>({
    mode: 'onTouched',
    defaultValues: expense,
  });

  const {
    register, handleSubmit, setValue, reset, control, formState: { isValid },
  } = useFormMethods;

  const handleUpdateExpenseSubmit = (updateExpenseAttributes: UpdateExpenseAttributes) => {
    setIsButtonLoading(true);

    updateExpense(updateExpenseAttributes);

    setIsButtonLoading(false);
  };

  const handleDeleteExpenseClick = (deleteExpenseAttributes: DeleteExpenseAttributes) => {
    deleteExpense(deleteExpenseAttributes);
    reset();
    onClose();
  };

  return (
    <>
      <DeleteExpenseModal
        isOpen={isDeleteExpenseModalOpen}
        onClose={() => setIsDeleteExpenseModalOpen(false)}
        expense={expense}
        deleteExpense={handleDeleteExpenseClick}
      />

      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(handleUpdateExpenseSubmit)} className="flex flex-col">
          <FormControl variant="standard" fullWidth>
            <TextField
              label="Descrição"
              variant="standard"
              fullWidth
              type="text"
              required
              classes={{ root: 'mb-5' }}
              {...register('description', { maxLength: 80 })}
            />
          </FormControl>

          <div className="flex flex-row mb-5">
            <FormControl variant="standard" className="mr-7" fullWidth>
              <Autocomplete
                id="cost-center-select"
                options={Object.values(CostCenter)}
                getOptionLabel={(option: CostCenter) => getCostCenterLabel(option)}
                value={costCenterAttribute}
                onChange={(_, newValue) => {
                  setValue('costCenter', formHelper.getEmptyStringFromUndefined(newValue));
                  setCostCenterAttribute(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="cost-center-select"
                    label="Centro de Custo"
                    variant="standard"
                    fullWidth
                    required
                  />
                )}
              />
            </FormControl>

            <Controller
              control={control}
              name="amount"
              render={({
                field: {
                  onChange, value, ref,
                },
              }) => (
                <AmountTextField
                  label="Valor"
                  fullWidth
                  variant="standard"
                  onChange={onChange}
                  value={value as number}
                  ref={ref}
                />
              )}
            />
          </div>
          <div className="flex flex-row mb-5">
            <FormControl variant="standard" className="mr-7" fullWidth>
              <Autocomplete
                id="payment-method-select"
                options={Object.values(PaymentMethod)}
                getOptionLabel={(option: PaymentMethod) => getPaymentMethodLabel(option)}
                value={paymentMethodAttribute}
                onChange={(_, newValue) => {
                  setValue('paymentMethod', formHelper.getEmptyStringFromUndefined(newValue));
                  setPaymentMethodAttribute(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="payment-method-select"
                    label="Forma de Pagamento"
                    variant="standard"
                    fullWidth
                    required
                  />
                )}
              />
            </FormControl>

            <Controller
              control={control}
              name="expenseDate"
              render={({
                field: {
                  onChange,
                  value,
                },
              }) => (
                <DatePickerInput onChange={onChange} value={value} label="Data da Despesa" />
              )}
            />
          </div>

          <div className="w-full flex flex-col mb-12">
            <TextField
              label="Observações"
              variant="standard"
              fullWidth
              multiline
              rows={3}
              {...register('notes', {
                maxLength: 80,
                setValueAs: (notes) => (notes.length > 0 ? notes : null),
              })}
            />
          </div>

          <div>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!isValid}
              loading={isButtonLoading}
            >
              Editar
            </LoadingButton>
            <Button onClick={() => setIsDeleteExpenseModalOpen(true)} className="ml-5" color="error">
              <DeleteIcon className="mr-1" />
              Deletar
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
