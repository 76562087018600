import axiosInstance from './config/axios';
import CreatePaymentRequest from './requests/payment/create-payment-request';
import GetDocumentRequest from './requests/payment/get-document-request';
import GetDocumentResponse from './responses/payment/get-document-response';
import GetPaymentsRequest from './requests/payment/get-payments-request';
import UpdatePaymentRequest from './requests/payment/update-payment-request';
import CreatePaymentResponse from './responses/payment/create-payment-response';
import GetPaymentResponse from './responses/payment/get-payment-response';
import GetPaymentsResponse from './responses/payment/get-payments-response';
import UpdatePaymentResponse from './responses/payment/update-payment-response';
import DeletePaymentRequest from './requests/payment/delete-payment-request';
import DeletePaymentResponse from './responses/payment/delete-payment-response';

const PAYMENT_BASE_URL = '/payment';

const getPayments = async (request: GetPaymentsRequest) => (axiosInstance.get<GetPaymentsResponse>(PAYMENT_BASE_URL, { params: request }));

const getPayment = async (paymentId: number) => (axiosInstance.get<GetPaymentResponse>(`${PAYMENT_BASE_URL}/${paymentId}`));

const getPaymentTrialsCsv = async (paymentId: number) => (axiosInstance.get<string>(`${PAYMENT_BASE_URL}/${paymentId}/trial/csv`));

const getDocument = async (request: GetDocumentRequest) => (axiosInstance.get<GetDocumentResponse>(
  `${PAYMENT_BASE_URL}/${request.paymentId}/document`,
  {
    params: { documentType: request.documentType },
    responseType: 'blob',
  },
));

const createPayment = async (request: CreatePaymentRequest) => (axiosInstance.post<CreatePaymentResponse>(PAYMENT_BASE_URL, request));

const updatePayment = async (request: UpdatePaymentRequest) => (axiosInstance.patch<UpdatePaymentResponse>(`${PAYMENT_BASE_URL}/${request.paymentId}`, request));

const deletePayment = async (request: DeletePaymentRequest) => (axiosInstance.delete<DeletePaymentResponse>(`${PAYMENT_BASE_URL}/${request.paymentId}`));

export default {
  getPayments,
  getPayment,
  createPayment,
  updatePayment,
  getDocument,
  getPaymentTrialsCsv,
  deletePayment,
};
