import React from 'react';
import {
  Typography,
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Trial } from '../../../../models';
import { CreatePaymentAttributes } from '../../../../interfaces';
import PaymentTrialInput from '../payment-trial-input/payment-trial-input';
import getPriceTagFromAmount from '../../../../shared/utils/payment/get-price-tag-from-amount';
import paymentTrialsInputFormService from '../trials-tab/services/payment-trials-input-form-service';

interface Props {
  trials: Trial[];
  setTrials?: (trials: Trial[]) => void;
}

export default function PaymentTrialsInputForm({ trials, setTrials }: Props) {
  const { control, watch } = useFormContext<CreatePaymentAttributes>();

  const { fields: paymentTrialsFields, remove } = useFieldArray({
    control,
    name: 'paymentTrials',
  });

  const effectivePaymentTrias = watch('paymentTrials');

  const handleDeleteTrial = (deletedTrial: Trial) => {
    remove(paymentTrialsFields.findIndex((paymentTrial) => paymentTrial.trialId === deletedTrial.trialId));

    if (setTrials) setTrials(trials.filter((trial) => deletedTrial.trialId !== trial.trialId));
  };

  return (
    <div className="flex flex-col w-full">
      <Typography variant="h4" classes={{ root: 'mb-1' }}>Processos</Typography>

      {paymentTrialsFields.map((paymentTrial, index) => (
        <div className="w-full mt-4" key={paymentTrial.trialId}>
          <PaymentTrialInput
            paymentTrialIndex={index}
            trial={trials.find((trial) => trial.trialId === paymentTrial.trialId) as Trial}
            onDeleteTrial={handleDeleteTrial}
            isDeleteTrialDisabled={trials.length === 1}
          />
        </div>
      ))}

      <div className="flex flex-row justify-between mt-7 pr-3 pl-3">
        <Typography variant="body1" color="commom.black">Total:</Typography>
        <Typography variant="h4">
          {getPriceTagFromAmount(paymentTrialsInputFormService.getTotalAmountFromPaymentTrials(effectivePaymentTrias))}
        </Typography>
      </div>
    </div>
  );
}
