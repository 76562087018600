import React, { useCallback, useEffect, useState } from 'react';
import {
  DeletePaymentAttributes,
  GetPaymentsAttributes, PaymentOrderableKeys, PaymentsFilterAttributes, SortDirection, UpdatePaymentAttributes,
} from '../../../../interfaces';
import { Payment } from '../../../../models';
import { PaymentStatus } from '../../../../shared/enums';
import { TopLayout } from './components';
import PaymentsFilterModal from './components/payments-filter-modal/payments-filter-modal';
import PaymentsTable from './components/payments-table/payments-table';
import ViewPaymentModal from '../view-payment-modal/view-payment-modal';
import paymentsService from './services/payments-service';
import paymentsTableService from './services/payments-table-service';

export default function PaymentsTab() {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loadingPayments, setLoadingPayments] = useState<boolean>(true);
  const [isPaymentsFilterModalOpen, setIsPaymentsFilterModalOpen] = useState<boolean>(false);
  const [paymentsFilterAttributes, setPaymentsFilterAttributes] = useState<PaymentsFilterAttributes | undefined>(undefined);
  const [paymentsSearchTerm, setPaymentsSearchTerm] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [orderByKey, setOrderByKey] = useState<keyof Payment>('paymentId');
  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const [isViewPaymentModalOpen, setIsViewPaymentModalOpen] = useState<boolean>(false);

  const openViewPaymentModal = (payment: Payment) => {
    setSelectedPayment(payment);
    setIsViewPaymentModalOpen(true);
  };

  const fetchPaymentsData = useCallback(async () => {
    setLoadingPayments(true);
    let getPaymentsAttributes: GetPaymentsAttributes = {
      search: paymentsSearchTerm.length > 0 ? paymentsSearchTerm : undefined,
      orderByColumn: paymentsTableService.getPaymentsOrderableColumnFromPaymentKey(orderByKey as keyof PaymentOrderableKeys),
      orderByDirection: sortDirection,
    };

    if (paymentsFilterAttributes) getPaymentsAttributes = { ...getPaymentsAttributes, ...paymentsFilterAttributes };

    const fetchedPayments = await paymentsService.getPayments(getPaymentsAttributes).catch(() => []);
    setPayments(fetchedPayments);

    setLoadingPayments(false);
  }, [paymentsFilterAttributes, paymentsSearchTerm, orderByKey, sortDirection]);

  const handleUpdatePaymentStatus = async (paymentStatus: PaymentStatus) => {
    if (!selectedPayment) return;

    const updatePaymentAttributes: UpdatePaymentAttributes = { paymentId: selectedPayment.paymentId, status: paymentStatus };

    await paymentsService.updatePayment(updatePaymentAttributes).then((updatedPayment) => {
      setSelectedPayment(updatedPayment);

      const updatedPayments = payments.map((payment) => {
        if (payment.paymentId === updatedPayment.paymentId) return updatedPayment;
        return payment;
      });

      setPayments(updatedPayments);
    }).catch(() => {
      setIsViewPaymentModalOpen(true);
    });
  };

  const closeViewPaymentModal = () => {
    setSelectedPayment(null);
    setIsViewPaymentModalOpen(false);
  };

  const handleUpdatePayment = async (updatePaymentAttributes: UpdatePaymentAttributes) => {
    await paymentsService.updatePayment(updatePaymentAttributes).then((updatedPayment) => {
      const updatedPayments = payments.map((payment) => {
        if (payment.paymentId === updatedPayment.paymentId) return updatedPayment;
        return payment;
      });

      setPayments(updatedPayments);
      closeViewPaymentModal();
    }).catch(() => {
      setIsViewPaymentModalOpen(true);
    });
  };

  const handleDeletePayment = async (deletePaymentAttributes: DeletePaymentAttributes) => {
    await paymentsService.deletePayment(deletePaymentAttributes);
    fetchPaymentsData();
    closeViewPaymentModal();
  };

  useEffect(() => {
    fetchPaymentsData();
  }, [fetchPaymentsData, paymentsFilterAttributes, paymentsSearchTerm, orderByKey, sortDirection]);
  return (
    <div className="w-full">
      {selectedPayment && (
        <ViewPaymentModal
          isOpen={isViewPaymentModalOpen}
          onClose={closeViewPaymentModal}
          payment={selectedPayment}
          updatePaymentStatus={handleUpdatePaymentStatus}
          updatePayment={handleUpdatePayment}
          deletePayment={handleDeletePayment}
        />
      )}

      <PaymentsFilterModal
        isOpen={isPaymentsFilterModalOpen}
        onClose={() => setIsPaymentsFilterModalOpen(false)}
        onPaymentsFilterModalChange={(filterAttributes) => setPaymentsFilterAttributes(filterAttributes)}
      />

      <div className="w-full mb-5">
        <TopLayout
          onSearchTermChange={(searchTerm) => setPaymentsSearchTerm(searchTerm)}
          onOpenPaymentsFilterModal={() => setIsPaymentsFilterModalOpen(true)}
        />
      </div>

      <PaymentsTable
        payments={payments}
        loadingPayments={loadingPayments}
        onSelectPayment={(payment) => openViewPaymentModal(payment)}
        orderByKey={orderByKey}
        setOrderByKey={setOrderByKey}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <div className="max-w-full" />
    </div>
  );
}
