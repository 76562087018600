import React from 'react';
import {
  Checkbox, FormControlLabel, IconButton, Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { CreatePaymentAttributes } from '../../../../interfaces';
import { DatePickerInput } from '../../../../shared/components';
import AmountTextField from '../../../../shared/components/amount-text-field/amount-text-field';

interface Props {
  installmentIndex: number;
  onDeleteInstallment: (installmentIndex: number) => void;
  isDeleteInstallmentDisabled: boolean;
}

export default function InstallmentInput({ installmentIndex, onDeleteInstallment, isDeleteInstallmentDisabled }: Props) {
  const { control } = useFormContext<CreatePaymentAttributes>();

  return (
    <div className="flex flex-row h-15 w-full">
      <div className="flex flex-row w-10/12 justify-around h-full items-center">
        <Typography variant="h4" color="primary.main">{`${installmentIndex + 1}.`}</Typography>

        <div className="flex flex-row w-10/12 justify-around h-full items-center">
          <div className="w-5/12 h-full items-center">
            <Controller
              control={control}
              name={`installments.${installmentIndex}.dueDate`}
              render={({
                field: {
                  onChange,
                  value,
                },
              }) => (
                <DatePickerInput onChange={onChange} value={value} label="Prazo" />
              )}
            />
          </div>

          <div className="w-5/12 h-full items-center">
            <Controller
              control={control}
              name={`installments.${installmentIndex}.amount`}
              render={({
                field: {
                  onChange, value, ref,
                },
              }) => (
                <AmountTextField
                  label="Valor"
                  fullWidth
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  ref={ref}
                />
              )}
            />
          </div>
        </div>

        <div className="h-full  items-end">
          <FormControlLabel
            control={(
              <Controller
                control={control}
                name={`installments.${installmentIndex}.paid`}
                render={({
                  field: {
                    onChange, onBlur, value, ref,
                  },
                }) => (
                  <Checkbox
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                    ref={ref}
                  />
                )}
              />
        )}
            label="Pago"
          />
        </div>
      </div>

      <div className="flex flex-row w-2/12 p-4 h-full items-center justify-end">
        <IconButton
          size="small"
          onClick={() => onDeleteInstallment(installmentIndex)}
          disabled={isDeleteInstallmentDisabled}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
}
