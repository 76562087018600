import { CreatePaymentPaymentTrialsAttributes } from '../../../../../interfaces';

const getTotalAmountFromPaymentTrials = (paymentTrials: CreatePaymentPaymentTrialsAttributes[]): number => {
  const totalAmount = paymentTrials.reduce((previousTotalAmount, paymentTrial) => {
    const amount = previousTotalAmount + paymentTrial.amount;
    return amount;
  }, 0);

  return totalAmount;
};

const paymentTrialsInputFormService = {
  getTotalAmountFromPaymentTrials,
};

export default paymentTrialsInputFormService;
