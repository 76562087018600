import React from 'react';
import { DatailsViewTitle, DetailsViewField } from '../../../../../shared/components';
import { Payment } from '../../../../../models';

interface Props {
  payment: Payment;
}

export default function PaymentAgentSection(
  {
    payment,
  }: Props,
) {
  return (
    <div className="w-full flex flex-col">
      <DatailsViewTitle text="Agente Pagador" />
      <div className="flex flex-col">
        <DetailsViewField
          description="Agente pagador"
          text={payment.company.name}
        />

        {payment.email && (
          <DetailsViewField
            description="Email"
            text={payment.email}
          />
        )}
      </div>
    </div>
  );
}
