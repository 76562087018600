import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  TextField, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { Payment } from '../../../../models';
import { DeletePaymentAttributes, UpdatePaymentAttributes } from '../../../../interfaces';
import BillingInputForm from '../billing-input-form/billing-input-form';
import PaymentTrialsInputForm from '../payment-trials-input-form/payment-trials-input-form';
import InstallmentsInputForm from '../installments-input-form/installments-input-form';
import DeletePaymentModal from '../delete-payment-modal/delete-payment-modal';

interface Props {
  payment: Payment;
  updatePayment: (updatePaymentAttributes: UpdatePaymentAttributes) => void;
  deletePayment: (deletePaymentAttributes: DeletePaymentAttributes) => void;
  onClose: () => void;
}

export default function UpdatePaymentModalContent(
  {
    payment, updatePayment, deletePayment, onClose,
  }: Props,
) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isDeletePaymentModalOpen, setIsDeletePaymentModalOpen] = useState<boolean>(false);

  const useFormMethods = useForm<UpdatePaymentAttributes>({
    mode: 'onTouched',
    defaultValues: payment,
  });

  const {
    register, handleSubmit, reset, formState: { isValid },
  } = useFormMethods;

  const handleUpdatePaymentSubmit = (updatePaymentAttributes: UpdatePaymentAttributes) => {
    setIsButtonLoading(true);

    updatePayment(updatePaymentAttributes);
    setIsButtonLoading(false);

    onClose();
  };

  const handleDeletePaymentClick = (deletePaymentAttributes: DeletePaymentAttributes) => {
    deletePayment(deletePaymentAttributes);
    reset();
    onClose();
  };

  return (
    <>
      <DeletePaymentModal
        isOpen={isDeletePaymentModalOpen}
        onClose={() => setIsDeletePaymentModalOpen(false)}
        payment={payment}
        deletePayment={handleDeletePaymentClick}
      />

      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(handleUpdatePaymentSubmit)} className="flex flex-col">
          <div className="w-full mb-10">
            <BillingInputForm />
          </div>

          <div className="w-full mb-12">
            <PaymentTrialsInputForm trials={payment.paymentTrials.map((paymentTrial) => paymentTrial.trial)} />
          </div>

          <div className="w-full mb-12">
            <InstallmentsInputForm />
          </div>

          <div className="w-full flex flex-col mb-12">
            <Typography variant="h4" classes={{ root: 'mb-1' }}>Observações</Typography>
            <TextField
              variant="standard"
              fullWidth
              multiline
              rows={3}
              {...register('notes', {
                maxLength: 80,
                setValueAs: (notes) => (!notes || notes.length > 0 ? notes : null),
              })}
            />
          </div>

          <div>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!isValid}
              loading={isButtonLoading}
            >
              Editar
            </LoadingButton>
            <Button onClick={() => setIsDeletePaymentModalOpen(true)} className="ml-5" color="error">
              <DeleteIcon className="mr-1" />
              Deletar
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
