import React from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import amountTextFieldService from './services/amount-text-field-service';
import getNormalizedAmountFromCents from '../../utils/payment/get-normalized-amount-from-cents';

type Props = Omit<TextFieldProps, 'onChange' | 'value'> & {
  onChange: (amount: number) => void,
  value: number
  defaultValue?: number
};

const AmountTextField = React.forwardRef<HTMLInputElement, Props>(({ onChange, value, ...otherProps }, ref) => {
  const handleKeyDown = (key: string) => {
    if (!amountTextFieldService.legalKeys.find((legalKey) => legalKey === key)) return;

    onChange(amountTextFieldService.getStandarizedAmount(value, key));
  };

  return (
    <TextField
      inputRef={ref}
      InputProps={{
        startAdornment: (<InputAdornment position="start">$</InputAdornment>),
      }}
      {...otherProps}
      onKeyDown={(event) => handleKeyDown(event.key)}
      value={getNormalizedAmountFromCents(value)}
      name="amount-format"
      defaultValue={getNormalizedAmountFromCents(otherProps.defaultValue || 0)}
      id="amount-masked-text-field"
    />
  );
});

export default AmountTextField;
