import dayjs from 'dayjs';
import { Trial } from '../../../models';
import { TrialBillingStatus, TrialStatus } from '../../../shared/enums';

const isTrialPastDue = (trial: Trial) => {
  const deadline = dayjs(trial.deadline);
  const today = dayjs();

  return today.isAfter(deadline, 'day') && (trial.status !== TrialStatus.SENT && trial.status !== TrialStatus.WITHDRAWAL);
};

const isTrialBilled = (trial: Trial) => trial.billingStatus === TrialBillingStatus.BILLED;

const isTrialDone = (trial: Trial) => trial.status === TrialStatus.SENT || trial.status === TrialStatus.WITHDRAWAL;

const calcTrialRowColor = (trial: Trial) => {
  if (isTrialPastDue(trial)) {
    return 'rgba(255, 0, 0, 0.05)';
  }
  if (isTrialDone(trial)) {
    return 'rgba(0, 0, 0, 0.05)';
  }
  return 'inherit';
};

const trialsTableBodyService = {
  calcTrialRowColor,
  isTrialPastDue,
  isTrialBilled,
};

export default trialsTableBodyService;
