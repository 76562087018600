import React from 'react';
import {
  Paper, Table, TableContainer,
} from '@mui/material';
import { SortDirection } from '../../../../interfaces';
import { TableBodySkeleton, TableHeader, TablePagination } from '../../../../shared/components';
import cashFlowsTableService from '../../services/cash-flows-table-service';
import { CashFlow } from '../../../../models';
import CashFlowTableBody from '../cash-flow-table-body/cash-flow-table-body';
import useTableContext from '../../../../context/table/context';

interface Props {
  cashFlows: CashFlow[];
  loadingCashFlows: boolean;
  onSelectRow: (cashFlow: CashFlow) => void;
}

export default function CashFlowTable({
  cashFlows, loadingCashFlows, onSelectRow,
}: Props) {
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('desc');
  const [orderByKey, setOrderByKey] = React.useState<keyof CashFlow>('transactionDate');
  const [page, setPage] = React.useState(0);

  const { defaultRowsPerPage } = useTableContext();
  const effectiveRowsPerPage = defaultRowsPerPage - 1;

  return (
    <Paper>
      <TableContainer style={{ tableLayout: 'fixed' }}>
        <Table
          classes={{ root: 'max-w-full whitespace-nowrap' }}
          size="medium"
        >
          <TableHeader<CashFlow>
            headerCells={cashFlowsTableService.headerCells}
            sortDirection={sortDirection}
            orderByKey={orderByKey}
            setOrderByKey={setOrderByKey}
            setSortDirection={setSortDirection}
          />

          {!loadingCashFlows && (
            <CashFlowTableBody
              cashFlows={cashFlows}
              page={page}
              sortDirection={sortDirection}
              orderByKey={orderByKey}
              onSelectRow={onSelectRow}
            />
          )}

          {loadingCashFlows && (
            <TableBodySkeleton
              rowsPerPage={effectiveRowsPerPage}
              columnsAmount={cashFlowsTableService.columnsAmount}
            />
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPage={effectiveRowsPerPage}
        recordsAmount={cashFlows.length}
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}
