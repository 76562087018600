import { CreatePaymentInstallmentsAttributes } from '../../../../../interfaces';

const getTotalAmountFromInstallments = (installments: CreatePaymentInstallmentsAttributes[]): number => {
  const totalAmount = installments.reduce((previousTotalAmount, installment) => {
    const amount = previousTotalAmount + installment.amount;
    return amount;
  }, 0);

  return totalAmount;
};

const getInstallmentInputFormKey = (index: number) => index;

const installmentsInputFormService = {
  getTotalAmountFromInstallments,
  getInstallmentInputFormKey,
};

export default installmentsInputFormService;
