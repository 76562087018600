import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  CreatePaymentAttributes, DeletePaymentAttributes, GetPaymentDocumentAttributes, GetPaymentsAttributes, UpdatePaymentAttributes,
} from '../../../../../interfaces';
import { api } from '../../../../../libs';
import ErrorResponse from '../../../../../libs/api/responses/error-response';
import CreatePaymentResponse from '../../../../../libs/api/responses/payment/create-payment-response';
import DeletePaymentResponse from '../../../../../libs/api/responses/payment/delete-payment-response';
import GetDocumentResponse from '../../../../../libs/api/responses/payment/get-document-response';
import GetPaymentResponse from '../../../../../libs/api/responses/payment/get-payment-response';
import GetPaymentsResponse from '../../../../../libs/api/responses/payment/get-payments-response';
import UpdatePaymentResponse from '../../../../../libs/api/responses/payment/update-payment-response';
import { Payment } from '../../../../../models';
import { PaymentDocumentType } from '../../../../../shared/enums';
import { PAYMENT_REICEIPT_DOCUMENT_NAME, PAYMENT_REPORT_DOCUMENT_NAME, PAYMENT_TRIALS_CSV_FILE_NAME } from '../../../../../shared/utils/constants';
import fileSeverHelper from '../../../../../shared/utils/file-saver-helper';
import toastMessages from '../../../../../shared/utils/toast-messages';

const getPayments = async (getPaymentsAttributes: GetPaymentsAttributes): Promise<GetPaymentsResponse> => {
  const getPaymentsResponse: AxiosResponse<GetPaymentsResponse> = await api.payment.getPayments(getPaymentsAttributes);
  return getPaymentsResponse.data;
};

const getPayment = async (paymentId: number): Promise<Payment> => {
  let getPaymentResponse: AxiosResponse<GetPaymentResponse>;
  try {
    getPaymentResponse = await api.payment.getPayment(paymentId);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  return getPaymentResponse?.data;
};

const createPayment = async (createPaymentAttributes: CreatePaymentAttributes): Promise<Payment> => {
  let createPaymentResponse: AxiosResponse<CreatePaymentResponse>;
  try {
    createPaymentResponse = await api.payment.createPayment(createPaymentAttributes);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  toast.success(toastMessages.success.DEFAULT);
  return createPaymentResponse?.data;
};

const updatePayment = async (updatePaymentAttributes: UpdatePaymentAttributes): Promise<Payment> => {
  let updatePaymentResponse: AxiosResponse<UpdatePaymentResponse>;
  try {
    updatePaymentResponse = await api.payment.updatePayment(updatePaymentAttributes);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  toast.success(toastMessages.success.DEFAULT);

  return updatePaymentResponse?.data;
};

const buildFileName = (payment: Payment, documentType: PaymentDocumentType): string => {
  const fileTypeName = documentType === PaymentDocumentType.REPORT
    ? PAYMENT_REPORT_DOCUMENT_NAME : PAYMENT_REICEIPT_DOCUMENT_NAME;

  const client = payment?.company?.name || '';

  const plaintiffs = payment?.paymentTrials?.map((paymentTrial) => {
    const plaintiff = paymentTrial?.trial?.plaintiff?.replace(/\s/g, '_') || '';
    return plaintiff;
  }).join('_');

  return `${fileTypeName}_${client}_${plaintiffs.slice(0, 90)}`;
};

const downloadPaymentDocument = async (getPaymentDocumentAttributes: GetPaymentDocumentAttributes) => {
  let getPaymentDocumentResponse: AxiosResponse<GetDocumentResponse>;
  try {
    getPaymentDocumentResponse = await api.payment.getDocument(getPaymentDocumentAttributes);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  const fileName = getPaymentDocumentAttributes?.payment ? buildFileName(getPaymentDocumentAttributes.payment, getPaymentDocumentAttributes.documentType) : '';

  fileSeverHelper.savePdfFile(getPaymentDocumentResponse?.data, fileName);
};

const downloadPaymentTrialsCsv = async (paymentId: number) => {
  let getPaymentTrialsCsvResponse: AxiosResponse<string>;

  try {
    getPaymentTrialsCsvResponse = await api.payment.getPaymentTrialsCsv(paymentId);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  if (!getPaymentTrialsCsvResponse?.data) {
    toast.error(toastMessages.error.DEFAULT);
    return;
  }

  fileSeverHelper.saveCsvFile(getPaymentTrialsCsvResponse.data, PAYMENT_TRIALS_CSV_FILE_NAME);
};

const deletePayment = async (deletePaymentsAttributes: DeletePaymentAttributes): Promise<DeletePaymentResponse> => {
  let deletePaymentResponse: AxiosResponse<DeletePaymentResponse>;
  try {
    deletePaymentResponse = await api.payment.deletePayment(deletePaymentsAttributes);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  toast.success(toastMessages.success.DEFAULT);

  return deletePaymentResponse?.data;
};

const paymentsService = {
  getPayments,
  getPayment,
  createPayment,
  updatePayment,
  downloadPaymentDocument,
  downloadPaymentTrialsCsv,
  deletePayment,
};

export default paymentsService;
