import dayjs from 'dayjs';
import { CreatePaymentInstallmentsAttributes, CreatePaymentPaymentTrialsAttributes } from '../../../../../interfaces';
import { Trial } from '../../../../../models';
import dateHelper from '../../../../../shared/utils/date-helper';

const getDefaultPaymentTrialsAttributesFromTrials = (trials: Trial[]): CreatePaymentPaymentTrialsAttributes[] => {
  const paymentTrials = trials.map((trial) => {
    const paymentTrial: CreatePaymentPaymentTrialsAttributes = {
      trialId: trial.trialId,
      amount: trial.amount,
    };

    return paymentTrial;
  });

  return paymentTrials;
};

const getDefaultInstallment = (): CreatePaymentInstallmentsAttributes => ({
  amount: 0,
  dueDate: dateHelper.getStandardizedDate(dayjs()),
  paid: false,
});

const createPaymentModalService = {
  getDefaultPaymentTrialsAttributesFromTrials,
  getDefaultInstallment,
};

export default createPaymentModalService;
