import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Checkbox,
  FormControl, FormControlLabel, TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePickerInput, Modal } from '../../../../../../shared/components';
import {
  PaymentMethod, PaymentStatus,
} from '../../../../../../shared/enums';
import companiesService from '../../../../../companies/services/companies-service';
import Company from '../../../../../../models/company';
import PaymentsFilterAttributes from '../../../../../../interfaces/payments/payments-filter-attributes';
import formHelper from '../../../../../../shared/utils/form-helper';
import getPaymentStatusAttributes from '../../../../../../shared/utils/payment/get-payment-status-attributes';
import getPaymentMethodLabel from '../../../../../../shared/utils/payment/get-payment-method-label';

interface Props {
  onPaymentsFilterModalChange: (paymentsFilterAttributes: PaymentsFilterAttributes) => void;
  isOpen: boolean;
  onClose: () => void;
}

export default function PaymentsFilterModal(
  {
    onPaymentsFilterModalChange, isOpen, onClose,
  }: Props,
) {
  const [isCompaniesFetching, setIsCompaniesFetching] = useState(true);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [statusFilter, setStatusFilter] = useState<PaymentStatus | null>(null);
  const [paymentMethodFilter, setPaymentMethodFilter] = useState<PaymentMethod | null>(null);
  const [companyFilter, setCompanyFilter] = useState<Company | null>(null);

  const fetchCompaniesData = async () => {
    setIsCompaniesFetching(true);
    const fetchedCompanies = await companiesService.getCompanies().catch(() => []);
    setCompanies(fetchedCompanies);
    setIsCompaniesFetching(false);
  };

  const {
    handleSubmit, control, setValue,
  } = useForm<PaymentsFilterAttributes>({ mode: 'onTouched' });

  const handleOnClose = () => {
    onClose();
  };

  const handlePaymentsFilterChange = (paymentsFilterAttributes: PaymentsFilterAttributes) => {
    onPaymentsFilterModalChange(formHelper.setEmptyStringsToUndefined<PaymentsFilterAttributes>(paymentsFilterAttributes));
    handleOnClose();
  };

  useEffect(() => {
    fetchCompaniesData();
  }, []);

  return (
    <Modal title="Filtragem de Pagamentos" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handlePaymentsFilterChange)} className="flex flex-col">
        <div className="w-full flex flex-row justify-between mb-5">
          <div className="w-[25%]">
            <FormControl variant="standard" fullWidth>
              <Autocomplete
                id="payment-status-select"
                options={Object.values(PaymentStatus)}
                getOptionLabel={(option: PaymentStatus) => getPaymentStatusAttributes(option).text}
                value={statusFilter}
                onChange={(_, newValue) => {
                  setValue('status', formHelper.getEmptyStringFromUndefined(newValue));
                  setStatusFilter(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="payment-status-select"
                    label="Status"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="w-[34%]">
            <FormControl variant="standard" fullWidth>
              <Autocomplete
                id="role-select-payment-method"
                options={Object.values(PaymentMethod)}
                getOptionLabel={(option: PaymentMethod) => getPaymentMethodLabel(option)}
                value={paymentMethodFilter}
                onChange={(_, newValue) => {
                  setValue('paymentMethod', formHelper.getEmptyStringFromUndefined(newValue));
                  setPaymentMethodFilter(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="payment-method-select"
                    label="Método de Pagamento"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="w-[34%]">
            <FormControl variant="standard" fullWidth>
              <Autocomplete
                id="role-select-client"
                options={companies}
                disabled={isCompaniesFetching}
                getOptionLabel={(option: Company) => option.name}
                value={companyFilter}
                onChange={(_, newValue) => {
                  setValue('companyId', formHelper.getEmptyStringFromUndefined(newValue?.companyId));
                  setCompanyFilter(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="task-type-select"
                    label="Agente Pagador"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </div>
        </div>

        <div className="w-full flex flex-row justify-between mb-5">
          <div className="w-[48%]">
            <Controller
              control={control}
              name="fromCreatedAt"
              render={({
                field: {
                  onChange,
                  value,
                },
              }) => (
                <DatePickerInput onChange={onChange} value={value} label="De data de criação" />
              )}
            />
          </div>

          <div className="w-[48%]">
            <Controller
              control={control}
              name="toCreatedAt"
              render={({
                field: {
                  onChange,
                  value,
                },
              }) => (
                <DatePickerInput onChange={onChange} value={value} label="Até data de criação" />
              )}
            />
          </div>
        </div>

        <div className="w-full flex flex-row justify-between mb-5">
          <div className="w-[48%]">
            <Controller
              control={control}
              name="fromDueDate"
              render={({
                field: {
                  onChange,
                  value,
                },
              }) => (
                <DatePickerInput onChange={onChange} value={value} label="De prazo" />
              )}
            />
          </div>

          <div className="w-[48%]">
            <Controller
              control={control}
              name="toDueDate"
              render={({
                field: {
                  onChange,
                  value,
                },
              }) => (
                <DatePickerInput onChange={onChange} value={value} label="Até prazo" />
              )}
            />
          </div>
        </div>

        <FormControlLabel
          classes={{ root: 'mb-6' }}
          control={(
            <Controller
              control={control}
              name="pastDue"
              render={({
                field: {
                  onChange, onBlur, value, ref,
                },
              }) => (
                <Checkbox
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value}
                  ref={ref}
                />
              )}
            />
          )}
          label="Somente atrasados"
        />

        <div>
          <LoadingButton
            variant="contained"
            type="submit"
          >
            Aplicar filtro
          </LoadingButton>
        </div>
      </form>
    </Modal>
  );
}
