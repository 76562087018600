import { TrialStatus } from '../../enums';

const trialStatusMapping = {
  [TrialStatus.PENDING]: {
    color: 'bg-yellow-light',
    text: 'Pendente',
  },
  [TrialStatus.IN_PROGRESS]: {
    color: 'bg-gray-100',
    text: 'Em progresso',
  },
  [TrialStatus.TO_CHECK]: {
    color: 'bg-cyan-light',
    text: 'A conferir',
  },
  [TrialStatus.IN_CONFERENCE]: {
    color: 'bg-purple-light',
    text: 'Em conferência',
  },
  [TrialStatus.DOCUMENTATION_REQUEST]: {
    color: 'bg-orange-light',
    text: 'Doc. pendente',
  },
  [TrialStatus.CHECKED]: {
    color: 'bg-lime-lightest',
    text: 'Conferido',
  },
  [TrialStatus.WITHDRAWAL]: {
    color: 'bg-slate-light',
    text: 'Destituído',
  },
  [TrialStatus.SENT]: {
    color: 'white',
    text: 'Enviado',
  },
};

const getTrialStatusAttributes = (trialStatus: TrialStatus) => trialStatusMapping[trialStatus];

export default getTrialStatusAttributes;
