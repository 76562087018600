import React from 'react';
import {
  Button,
  Toolbar, Typography,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import UpdateTrialBillingStatusAttributes from '../../../../../../interfaces/trials/update-trial-billing-status-attributes';
import { TrialBillingStatus } from '../../../../../../shared/enums';
import { Trial } from '../../../../../../models';

interface Props {
  selectedTrials: Trial[];
  updateTrialBillingStatus: (status: UpdateTrialBillingStatusAttributes) => void;
}

function TrialsTableToolbar({ selectedTrials, updateTrialBillingStatus }: Props) {
  const handleUpdateTrialBillingStatus = (status: TrialBillingStatus) => {
    const trailIds = selectedTrials.reduce((acc: number[], trail: Trial) => {
      acc.push(trail.trialId);
      return acc;
    }, []);

    const attributes: UpdateTrialBillingStatusAttributes = {
      billingStatus: status,
      trialIds: trailIds,
    };

    updateTrialBillingStatus(attributes);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selectedTrials.length > 0 && {
          bgcolor: 'bg-purple-light',
        }),
      }}
    >
      {selectedTrials.length > 0 ? (
        <div className="flex flex-row bg-white rounded-full items-center -mt-6">
          <div className="flex flex-row ml-5 -mt-1 items-center">
            <CheckBoxIcon className="mr-1" fontSize="medium" />
            <Typography
              color="inherit"
              variant="subtitle1"
              component="div"
              noWrap
            >
              {selectedTrials.length}
              {' '}
            </Typography>
          </div>
          <div className="ml-5 whitespace-nowrap">
            <Button
              color="secondary"
              onClick={() => handleUpdateTrialBillingStatus(TrialBillingStatus.NON_BILLABLE)}
            >
              Não cobrável
            </Button>

          </div>
          <div className="ml-5 mr-5 whitespace-nowrap">
            <Button
              color="secondary"
              onClick={() => handleUpdateTrialBillingStatus(TrialBillingStatus.UNBILLED)}
            >
              Não faturado
            </Button>
          </div>
        </div>

      ) : (
        <>
        </>
      )}
    </Toolbar>
  );
}

export default TrialsTableToolbar;
