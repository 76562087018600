import { HeaderCell } from '../../../interfaces';
import { CashFlow } from '../../../models';

const headerCells: HeaderCell<CashFlow>[] = [
  {
    key: 'description',
    label: 'Descrição',
    sortable: false,
    width: '20%',
  },
  {
    key: 'cashFlowId',
    label: 'Identificador',
    sortable: false,
    width: '5%',
  },
  {
    key: 'transactionDate',
    label: 'Data de Movimentação',
    sortable: true,
    width: '20%',
  },
  {
    key: 'paymentMethod',
    label: 'Meio de Pagamento',
    sortable: false,
    width: '20%',
  },
  {
    key: 'source',
    label: 'Área de Custo/Empresa',
    sortable: false,
    width: '20%',
  },
  {
    key: 'amount',
    label: 'Valor',
    sortable: false,
    width: '15%',
  },
];

const columnsAmount = headerCells.length;

const cashFlowsTableService = {
  headerCells,
  columnsAmount,
};

export default cashFlowsTableService;
