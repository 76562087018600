import { TrialBillingStatus } from '../../enums';

const trialBillingStatusMapping = {
  [TrialBillingStatus.BILLED]: {
    color: 'bg-lime-lightest',
    text: 'Faturado',
  },
  [TrialBillingStatus.UNBILLED]: {
    color: 'bg-gray-100',
    text: 'Não Faturado',
  },
  [TrialBillingStatus.NON_BILLABLE]: {
    color: 'bg-cyan-main',
    text: 'Não Cobrável',
  },
};

const getTrialBillingStatusAttributes = (trialBillingStatus: TrialBillingStatus) => trialBillingStatusMapping[trialBillingStatus];

export default getTrialBillingStatusAttributes;
