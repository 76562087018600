import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CashFlow } from '../../../../models';
import { CashFlowType } from '../../../../shared/enums';
import tableHelper from '../../../../shared/utils/table-helper';

interface Props {
  cashFlow: CashFlow;
}

export default function CashFlowDescriptionLabel({ cashFlow }: Props) {
  return (
    <div className="flex flex-row">
      {cashFlow.type === CashFlowType.INFLOW
        && <ArrowUpwardIcon color="success" />}

      {cashFlow.type === CashFlowType.OUTFLOW
        && <ArrowDownwardIcon color="error" />}

      <p className="ml-3">{tableHelper.getLimitedCellTextContent(cashFlow.description, 30)}</p>
    </div>
  );
}
