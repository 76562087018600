import React from 'react';
import { DatailsViewTitle } from '../../../../../shared/components';
import { Payment } from '../../../../../models';
import InstallmentView from './installment-view';

interface Props {
  payment: Payment;
}

export default function InstallmentsSection(
  {
    payment,
  }: Props,
) {
  return (
    <div className="w-full flex flex-col">
      <DatailsViewTitle text="Parcelas" />
      <div className="flex flex-col">
        {payment.installments.map((installment, index) => (
          <div className={`w-full ${index !== 0 && 'mt-4'}`} key={installment.installmentId}>
            <InstallmentView
              installment={installment}
              installmentIndex={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
