import * as React from 'react';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tab: number;
}

export default function TabPanel(props: TabPanelProps) {
  const {
    children, tab, index,
  } = props;

  return (
    <div
      hidden={tab !== index}
      id={`${index}`}
    >
      {tab === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
