import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { CashFlow } from '../../../../models';
import { CashFlowType } from '../../../../shared/enums';
import getPriceTagFromAmount from '../../../../shared/utils/payment/get-price-tag-from-amount';

interface Props {
  cashFlows: CashFlow[];
}

export default function CashFlowResume({ cashFlows }: Props) {
  const calculateTotalCashFlow = (cashFlowType: CashFlowType.INFLOW | CashFlowType.OUTFLOW) => {
    const total = cashFlows.reduce((acc, cashFlow) => {
      if (cashFlow.type === cashFlowType) {
        return acc + cashFlow.amount;
      }
      return acc;
    }, 0);
    return total;
  };

  return (
    <div className="flex flex-row bg-white rounded-md p-3 gap-6">
      <strong className="text-gray-800">Resumo do Período</strong>
      <div>
        <ArrowUpwardIcon className="mr-1" color="success" />
        {'Entradas: '}
        {getPriceTagFromAmount(
          calculateTotalCashFlow(CashFlowType.INFLOW),
        )}
      </div>
      <div>
        <ArrowDownwardIcon className="mr-1" color="error" />
        {'Saída: '}
        {getPriceTagFromAmount(
          calculateTotalCashFlow(CashFlowType.OUTFLOW),
        )}
      </div>
      <div>
        <SwapVertIcon className="mr-1" color="primary" />
        {'Saldo: '}
        {getPriceTagFromAmount(
          calculateTotalCashFlow(CashFlowType.INFLOW) - calculateTotalCashFlow(CashFlowType.OUTFLOW),
        )}
      </div>
    </div>
  );
}
