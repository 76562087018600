import React, { useState } from 'react';
import { Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import PaymentsIcon from '@mui/icons-material/Payments';
import DownloadIcon from '@mui/icons-material/Download';
import { DatailsViewTitle } from '../../../../../shared/components';
import DownloadReceiptDocumentTypeModal from './download-receipt-document-type-modal';

interface Props {
  onReportDownload: () => void;
  onReceiptDownload: (showDueDate: boolean) => void;
  onCsvDownload: () => void;
}

export default function ActionsSection(
  {
    onReportDownload, onReceiptDownload, onCsvDownload,
  }: Props,
) {
  const [isDownloadReceiptDocumentTypeModalOpen, setIsDownloadReceiptDocumentTypeModalOpen] = useState(false);

  return (
    <div className="w-full flex flex-col">
      <DownloadReceiptDocumentTypeModal
        isOpen={isDownloadReceiptDocumentTypeModalOpen}
        onClose={() => setIsDownloadReceiptDocumentTypeModalOpen(false)}
        onReceiptDownload={(showDueDate) => onReceiptDownload(showDueDate)}
      />

      <DatailsViewTitle text="Ações Rápidas" />
      <div className="flex flex-row">
        <Button
          variant="outlined"
          onClick={onReportDownload}
          startIcon={<DescriptionIcon />}
          classes={{ root: 'mr-2' }}
        >
          BAIXAR RELATÓRIO
        </Button>

        <Button
          variant="outlined"
          onClick={() => setIsDownloadReceiptDocumentTypeModalOpen(true)}
          startIcon={<PaymentsIcon />}
          classes={{ root: 'mr-2' }}
        >
          BAIXAR RECIBO
        </Button>

        <Button
          variant="outlined"
          onClick={() => onCsvDownload()}
          startIcon={<DownloadIcon />}
        >
          BAIXAR CSV
        </Button>
      </div>
    </div>
  );
}
