import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  TextField, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CreatePaymentAttributes } from '../../../../../../interfaces';
import { Modal } from '../../../../../../shared/components';
import { Trial } from '../../../../../../models';
import BillingInputForm from '../../../billing-input-form/billing-input-form';
import PaymentTrialsInputForm from '../../../payment-trials-input-form/payment-trials-input-form';
import createPaymentModalService from '../../services/create-payment-modal-service';
import InstallmentsInputForm from '../../../installments-input-form/installments-input-form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  trials: Trial[];
  setTrials: (trials: Trial[]) => void
  createPayment: (createPaymentAttributes: CreatePaymentAttributes) => void;
}

export default function CreatePaymentModal(
  {
    isOpen, onClose, trials, setTrials, createPayment,
  }: Props,
) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const useFormMethods = useForm<CreatePaymentAttributes>({
    mode: 'onTouched',
    defaultValues: {
      paymentTrials: createPaymentModalService.getDefaultPaymentTrialsAttributesFromTrials(trials),
      installments: [createPaymentModalService.getDefaultInstallment()],
      email: null,
    },
  });
  const {
    register, handleSubmit, formState: { isValid },
  } = useFormMethods;

  const handleCreatePaymentSubmit = (createPaymentAttributes: CreatePaymentAttributes) => {
    setIsButtonLoading(true);

    createPayment(createPaymentAttributes);

    setIsButtonLoading(false);
  };

  return (
    <Modal title="Novo Pagamento" isOpen={isOpen} onClose={onClose}>
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(handleCreatePaymentSubmit)} className="flex flex-col">
          <div className="w-full mb-10">
            <BillingInputForm />
          </div>

          <div className="w-full mb-12">
            <PaymentTrialsInputForm trials={trials} setTrials={setTrials} />
          </div>

          <div className="w-full mb-12">
            <InstallmentsInputForm />
          </div>

          <div className="w-full flex flex-col mb-12">
            <Typography variant="h4" classes={{ root: 'mb-1' }}>Observações</Typography>
            <TextField
              variant="standard"
              fullWidth
              multiline
              rows={3}
              {...register('notes', {
                maxLength: 80,
                setValueAs: (notes) => (notes.length > 0 ? notes : null),
              })}
            />
          </div>

          <div>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!isValid}
              loading={isButtonLoading}
            >
              Criar
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}
