import React from 'react';
import {
  IconButton, Typography,
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { CreatePaymentAttributes } from '../../../../interfaces';
import getPriceTagFromAmount from '../../../../shared/utils/payment/get-price-tag-from-amount';
import InstallmentInput from '../installment-input/installment-input';
import createPaymentModalService from '../trials-tab/services/create-payment-modal-service';
import installmentsInputFormService from '../trials-tab/services/installments-input-form-service';

export default function InstallmentsInputForm() {
  const { control, watch } = useFormContext<CreatePaymentAttributes>();

  const { fields: installmentsFields, remove, append } = useFieldArray({
    control,
    name: 'installments',
  });

  const effectiveInstallments = watch('installments');

  return (
    <div className="flex flex-col w-full">
      <Typography variant="h4" classes={{ root: 'mb-1' }}>Parcelas</Typography>

      {installmentsFields.map((_, index) => (
        <div className="mt-6 w-full" key={installmentsInputFormService.getInstallmentInputFormKey(index)}>
          <InstallmentInput
            installmentIndex={index}
            onDeleteInstallment={(installmentIndex) => remove(installmentIndex)}
            isDeleteInstallmentDisabled={installmentsFields.length === 1}
          />
        </div>
      ))}

      <div className="flex flex-row justify-center w-full mt-6">
        <IconButton
          classes={{ root: 'bg-purple-light' }}
          color="secondary"
          onClick={() => append(createPaymentModalService.getDefaultInstallment())}
        >
          <AddIcon color="primary" />
        </IconButton>
      </div>

      <div className="flex flex-row justify-between mt-4 pr-3 pl-3">
        <Typography variant="body1" color="commom.black">Soma das parcelas:</Typography>
        <Typography variant="h4">
          {getPriceTagFromAmount(installmentsInputFormService.getTotalAmountFromInstallments(effectiveInstallments))}
        </Typography>
      </div>
    </div>
  );
}
