import React from 'react';
import { DatailsViewTitle, DetailsViewField } from '../../../../../shared/components';
import { Payment } from '../../../../../models';
import getPriceTagFromAmount from '../../../../../shared/utils/payment/get-price-tag-from-amount';
import getPaymentMethodLabel from '../../../../../shared/utils/payment/get-payment-method-label';
import dateHelper from '../../../../../shared/utils/date-helper';

interface Props {
  payment: Payment;
}

export default function GeneralPaymentSection(
  {
    payment,
  }: Props,
) {
  const getPaymentInstallmentsTotalAmount = () => {
    const calculatedTotalAmount = payment.installments.reduce((totalAmount, installment) => totalAmount + installment.amount, 0);
    return calculatedTotalAmount;
  };

  const getPaymentInstallmentsTotalPaidAmount = () => {
    const calculatedTotalPaidAmount = payment.installments.reduce((totalPaidAmount, installment) => {
      if (installment.paid) return totalPaidAmount + installment.amount;
      return totalPaidAmount;
    }, 0);

    return calculatedTotalPaidAmount;
  };

  return (
    <div className="w-full flex flex-col">
      <DatailsViewTitle text="Pagamento" />
      <div className="flex flex-col">
        <div className="flex flex-row justify-start">
          <DetailsViewField
            description="Identificador"
            text={payment.paymentId.toString()}
          />
        </div>

        <div className="flex flex-row space-x-7">
          <DetailsViewField
            description="Valor"
            boldDescription
            text={getPriceTagFromAmount(getPaymentInstallmentsTotalAmount())}
          />

          <DetailsViewField
            description="Valor Pago"
            boldDescription
            text={getPriceTagFromAmount(getPaymentInstallmentsTotalPaidAmount())}
          />

          <DetailsViewField
            description="Prazo"
            text={dateHelper.getLocaleDate(payment.dueDate)}
          />
        </div>

        <div className="flex flex-row space-x-7">
          {payment.invoice && (
            <DetailsViewField
              description="Nota fiscal"
              text={payment.invoice}
            />
          )}

          <DetailsViewField
            description="Forma de pagamento"
            text={getPaymentMethodLabel(payment.paymentMethod)}
          />
        </div>
      </div>
    </div>
  );
}
