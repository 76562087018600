import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { CreateExpenseAttributes, DeleteExpenseAttributes, UpdateExpenseAttributes } from '../../../interfaces';
import CashFlowsFilterAttributes from '../../../interfaces/cash-flows/cash-flows-filter-attributes';
import { api } from '../../../libs';
import GetCashFlowsResponse from '../../../libs/api/responses/cash-flow/get-cash-flows-response';
import ErrorResponse from '../../../libs/api/responses/error-response';
import CreateExpenseResponse from '../../../libs/api/responses/expense/create-expense-response';
import GetExpenseResponse from '../../../libs/api/responses/expense/get-expense-response';
import UpdateExpenseResponse from '../../../libs/api/responses/expense/update-expense-response';
import { CashFlow, Expense } from '../../../models';
import { CASH_FLOWS_CSV_FILE_NAME } from '../../../shared/utils/constants';
import fileSeverHelper from '../../../shared/utils/file-saver-helper';
import toastMessages from '../../../shared/utils/toast-messages';

const createExpense = async (createExpenseAttributes: CreateExpenseAttributes): Promise<Expense> => {
  let createExpenseResponse: AxiosResponse<CreateExpenseResponse>;
  try {
    createExpenseResponse = await api.expense.createExpense(createExpenseAttributes);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  toast.success(toastMessages.success.DEFAULT);
  return createExpenseResponse?.data;
};

const getCashFlows = async (getCashFlowsAttributes: CashFlowsFilterAttributes): Promise<GetCashFlowsResponse> => {
  let getCashFlowsResponse: AxiosResponse<GetCashFlowsResponse>;
  try {
    getCashFlowsResponse = await api.cashFlow.getCashFlows(getCashFlowsAttributes);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  return getCashFlowsResponse?.data;
};

const updateExpense = async (updateExpenseAttributes: UpdateExpenseAttributes): Promise<Expense> => {
  let updateExpenseResponse: AxiosResponse<UpdateExpenseResponse>;
  try {
    updateExpenseResponse = await api.expense.updateExpense(updateExpenseAttributes);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  toast.success(toastMessages.success.DEFAULT);

  return updateExpenseResponse?.data;
};

const getExpense = async (expenseId: number): Promise<Expense> => {
  let getExpenseResponse: AxiosResponse<GetExpenseResponse>;
  try {
    getExpenseResponse = await api.expense.getExpense(expenseId);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  return getExpenseResponse?.data;
};

const downloadCashFlowsCsv = async () => {
  let getCashFlowsCsvResponse: AxiosResponse<string>;

  try {
    getCashFlowsCsvResponse = await api.cashFlow.getCashFlowsCsv();
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  if (!getCashFlowsCsvResponse?.data) {
    toast.error(toastMessages.error.DEFAULT);
    return;
  }

  fileSeverHelper.saveCsvFile(getCashFlowsCsvResponse.data, CASH_FLOWS_CSV_FILE_NAME);
};

const deleteExpense = async (deleteExpensesAttributes: DeleteExpenseAttributes) => {
  try {
    await api.expense.deleteExpense(deleteExpensesAttributes);
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  toast.success(toastMessages.success.DEFAULT);
};

const partialMatchKeys = ['description', 'email'] as (keyof CashFlow)[];
const fullMatchKeys = ['cashFlowId', 'transactionDate', 'expenseMethod', 'amount', 'source', 'type'] as (keyof CashFlow)[];

const cashFlowService = {
  createExpense,
  getCashFlows,
  downloadCashFlowsCsv,
  updateExpense,
  getExpense,
  deleteExpense,
  partialMatchKeys,
  fullMatchKeys,
};

export default cashFlowService;
