import React from 'react';
import { DatailsViewTitle, DetailsViewField } from '../../../../../shared/components';

interface Props {
  notes: string;
}

export default function PaymentAgentSection(
  {
    notes,
  }: Props,
) {
  return (
    <div className="w-full flex flex-col">
      <DatailsViewTitle text="Observações" />
      <div className="flex flex-col">
        <DetailsViewField
          description="Anotações Públicas"
          text={notes}
        />
      </div>
    </div>
  );
}
