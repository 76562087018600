import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  CreatePaymentAttributes,
  DeletePaymentAttributes,
  GetTrialsAttributes, SortDirection, TrialOrderableKeys, UpdatePaymentAttributes,
} from '../../../../interfaces';
import TrialsFilterAttributes from '../../../../interfaces/trials/trials-filter-attributes';
import { Payment, Trial } from '../../../../models';
import trialsService from '../../../trials/services/trials-service';
import { CreatePaymentModal, TopLayout, TrialsTable } from './components';
import trialsTableBodyService from '../../../trials/services/trials-table-body-service';
import UpdateTrialBillingStatusAttributes from '../../../../interfaces/trials/update-trial-billing-status-attributes';
import trialsTableService from './services/trials-table-service';
import paymentsService from '../payments-tab/services/payments-service';
import TrialsFilterModal from './components/trials-filter-modal/trials-filter-modal';
import LoadingModal from '../../../../shared/components/laoding-modal/loading-modal';
import ViewPaymentModal from '../view-payment-modal/view-payment-modal';
import { PaymentStatus, TrialBillingStatus } from '../../../../shared/enums';

export default function TrialsTab() {
  const [trials, setTrials] = useState<Trial[]>([]);
  const [selectedTrials, setSelectedTrials] = useState<Trial[]>([]);
  const [loadingTrials, setLoadingTrials] = useState<boolean>(true);
  const [isTrialsFilterModalOpen, setIsTrialsFilterModalOpen] = useState<boolean>(false);
  const [trialsFilterAttributes, setTrialsFilterAttributes] = useState<TrialsFilterAttributes | undefined>(undefined);
  const [trialsSearchTerm, setTrialsSearchTerm] = useState<string>('');
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc');
  const [orderByKey, setOrderByKey] = React.useState<keyof Trial>('billingStatus');
  const [isCreatePaymentModalOpen, setIsCreatePaymentModalOpen] = useState<boolean>(false);
  const [trialPayment, setTrialPayment] = useState<Payment | null>(null);
  const [isLoadingPaymentModalOpen, setIsLoadingPaymentModalOpen] = useState<boolean>(false);
  const [isViewPaymentModalOpen, setIsViewPaymentModalOpen] = useState<boolean>(false);
  const [unbilledTrials, setUnbilledTrials] = useState<number>(0);

  useEffect(() => {
    const unbilledCount = trials.filter((trail) => trail.billingStatus === TrialBillingStatus.UNBILLED).length;
    setUnbilledTrials(unbilledCount);
  }, [trials]);

  const fetchTrialsData = useCallback(async () => {
    setLoadingTrials(true);
    let getTrialsAttributes: GetTrialsAttributes = {
      search: trialsSearchTerm.length > 0 ? trialsSearchTerm : undefined,
      orderByColumn: trialsTableService.getTrialOrderableColumnFromTrialKey(orderByKey as keyof TrialOrderableKeys),
      orderByDirection: sortDirection,
    };

    if (trialsFilterAttributes) getTrialsAttributes = { ...getTrialsAttributes, ...trialsFilterAttributes };

    const fetchedTrials = await trialsService.getTrials(getTrialsAttributes).catch(() => []);
    setTrials(fetchedTrials);

    setLoadingTrials(false);
  }, [trialsFilterAttributes, trialsSearchTerm, orderByKey, sortDirection]);

  const handleCreatePayment = async (createPaymentAttributes: CreatePaymentAttributes) => {
    await paymentsService.createPayment(createPaymentAttributes).then((createdPayment) => {
      const trialsFromPayment = createdPayment.paymentTrials.map((paymentTrial) => paymentTrial.trial);
      const updatedTrials = trials.map((trial) => {
        const billedTrial = trialsFromPayment.find((trialFromPayemnt) => trialFromPayemnt.trialId === trial.trialId);

        if (billedTrial) {
          return {
            ...trial,
            billingStatus: billedTrial.billingStatus,
            paymentId: createdPayment.paymentId,
          };
        }

        return trial;
      });

      setSelectedTrials([]);
      setTrials(updatedTrials);
      setIsCreatePaymentModalOpen(false);
    }).catch(() => {
      setIsCreatePaymentModalOpen(true);
    });
  };

  const closeViewPaymentModal = () => {
    setTrialPayment(null);
    setIsViewPaymentModalOpen(false);
  };

  const handleUpdateTrialBillingStatus = async (updateTrialBillingStatusAttributes: UpdateTrialBillingStatusAttributes) => {
    await trialsService.updateTrialBillingStatus(updateTrialBillingStatusAttributes).then(() => {
      const updatedTrials = trials.map((trial) => {
        if (updateTrialBillingStatusAttributes.trialIds.includes(trial.trialId)) {
          const updatedTrial = { ...trial, billingStatus: updateTrialBillingStatusAttributes.billingStatus };
          return updatedTrial;
        }
        return trial;
      });
      setTrials(updatedTrials);
      setSelectedTrials([]);
    }).catch(() => {
    });
  };

  const handleUpdatePayment = async (updatePaymentAttributes: UpdatePaymentAttributes) => {
    await paymentsService.updatePayment(updatePaymentAttributes).then(() => {
      closeViewPaymentModal();
    }).catch(() => {
      setIsViewPaymentModalOpen(true);
    });
  };

  const handleUpdatePaymentStatus = async (paymentStatus: PaymentStatus) => {
    if (!trialPayment) return;

    const updatePaymentAttributes: UpdatePaymentAttributes = { paymentId: trialPayment.paymentId, status: paymentStatus };

    await paymentsService.updatePayment(updatePaymentAttributes).then((updatedPayment) => {
      setTrialPayment(updatedPayment);
    }).catch(() => {
      setIsViewPaymentModalOpen(true);
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const filteredTrials = trials.filter((trial) => !trialsTableBodyService.isTrialBilled(trial));
      const newSelected = filteredTrials.map((trial) => trial);
      setSelectedTrials(newSelected);
      return;
    }
    setSelectedTrials([]);
  };

  const handleBilledTrialClick = async (trial: Trial) => {
    setIsLoadingPaymentModalOpen(true);
    await paymentsService.getPayment(trial.paymentId).then((payment) => {
      setTrialPayment(payment);
      setIsViewPaymentModalOpen(true);
    }).catch(() => setTrialPayment(null));
    setIsLoadingPaymentModalOpen(false);
  };

  const handleSelectNonBilledTrial = (trial: Trial) => {
    const selectedIndex = selectedTrials.indexOf(trial);
    let newSelected: Trial[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTrials, trial);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTrials.slice(1));
    } else if (selectedIndex === selectedTrials.length - 1) {
      newSelected = newSelected.concat(selectedTrials.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTrials.slice(0, selectedIndex),
        selectedTrials.slice(selectedIndex + 1),
      );
    }
    setSelectedTrials(newSelected);
  };

  const handleTrialClick = (trial: Trial) => {
    if (trialsTableBodyService.isTrialBilled(trial)) {
      handleBilledTrialClick(trial);
      return;
    }

    handleSelectNonBilledTrial(trial);
  };

  const handleDeletePayment = async (deletePaymentAttributes: DeletePaymentAttributes) => {
    await paymentsService.deletePayment(deletePaymentAttributes);
    fetchTrialsData();
    closeViewPaymentModal();
  };

  useEffect(() => {
    fetchTrialsData();
  }, [fetchTrialsData, trialsFilterAttributes, trialsSearchTerm, orderByKey, sortDirection]);

  return (
    <div className="w-full">
      <LoadingModal isOpen={isLoadingPaymentModalOpen} />

      {trialPayment && (
        <ViewPaymentModal
          isOpen={isViewPaymentModalOpen}
          onClose={closeViewPaymentModal}
          payment={trialPayment}
          updatePayment={handleUpdatePayment}
          updatePaymentStatus={handleUpdatePaymentStatus}
          deletePayment={handleDeletePayment}
        />
      )}

      {isCreatePaymentModalOpen && (
        <CreatePaymentModal
          createPayment={handleCreatePayment}
          trials={selectedTrials}
          setTrials={setSelectedTrials}
          isOpen={isCreatePaymentModalOpen}
          onClose={() => setIsCreatePaymentModalOpen(false)}
        />
      )}

      <TrialsFilterModal
        isOpen={isTrialsFilterModalOpen}
        onClose={() => setIsTrialsFilterModalOpen(false)}
        onTrialsFilterModalChange={(filterAttributes) => setTrialsFilterAttributes(filterAttributes)}
      />

      <div className="w-full">
        <TopLayout
          selectedTrials={selectedTrials}
          unbilledTrials={unbilledTrials}
          onOpenTrialsFilterModal={() => setIsTrialsFilterModalOpen(true)}
          onSearchTermChange={(searchTerm) => setTrialsSearchTerm(searchTerm)}
          onCreateNewPaymentClick={() => setIsCreatePaymentModalOpen(true)}
          updateTrialBillingStatus={handleUpdateTrialBillingStatus}
        />
      </div>

      <div className="max-w-full">
        <TrialsTable
          trials={trials}
          selectedTrials={selectedTrials}
          loadingTrials={loadingTrials}
          orderByKey={orderByKey}
          setOrderByKey={setOrderByKey}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          handleSelectAllClick={handleSelectAllClick}
          onTrialClick={handleTrialClick}
        />
      </div>
    </div>
  );
}
