import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  CreateExpenseAttributes, DeleteExpenseAttributes, DeletePaymentAttributes, UpdateExpenseAttributes, UpdatePaymentAttributes,
} from '../../interfaces';
import { CashFlow, Expense, Payment } from '../../models';
import searchHelper from '../../shared/utils/search-helper';
import { CashFlowsFilterModal, TopLayout } from './components';
import CashFlowTable from './components/cash-flow-table/cash-flow-table';
import CashFlowResume from './components/cash-flow-resume/cash-flow-resume';
import CreateExpenseModal from './components/create-expense-modal/create-expense-modal';
import cashFlowService from './services/cash-flows-service';
import paymentsService from '../payments/components/payments-tab/services/payments-service';
import LoadingModal from '../../shared/components/laoding-modal/loading-modal';
import formHelper from '../../shared/utils/form-helper';
import { CashFlowType, PaymentStatus } from '../../shared/enums';
import ViewPaymentModal from '../payments/components/view-payment-modal/view-payment-modal';
import ViewExpenseModal from './components/view-expense-modal/view-expense-modal';
import CashFlowsFilterAttributes from '../../interfaces/cash-flows/cash-flows-filter-attributes';
import dateHelper from '../../shared/utils/date-helper';

export default function Finances() {
  const [isCreateExpenseModalOpen, setIsCreateExpenseModalOpen] = useState<boolean>(false);
  const [loadingCashFlows, setLoadingCashFlows] = useState<boolean>(false);
  const [cashFlows, setCashFlows] = useState<CashFlow[]>([]);
  const [filteredCashFlows, setFilteredCashFlows] = useState<CashFlow[]>([]);
  const [cashFlowSearchTerm, setCashFlowSearchTerm] = useState<string>('');
  const [selectedExpense, setSelectedExpense] = useState<Expense | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const [isViewExpenseModalOpen, setIsViewExpenseModalOpen] = useState<boolean>(false);
  const [isViewPaymentModalOpen, setIsViewPaymentModalOpen] = useState<boolean>(false);
  const [isLoadingUpdateModalOpen, setIsLoadingUpdateModalOpen] = useState<boolean>(false);
  const [isCashFlowFilterModalOpen, setIsCashFlowFilterModalOpen] = useState<boolean>(false);
  const [cashFlowsFilterAttributes, setCashFlowsFilterAttributes] = useState<CashFlowsFilterAttributes>({
    fromTransactionDate: dateHelper.getStandardizedDate(dayjs().startOf('month')),
    toTransactionDate: dateHelper.getStandardizedDate(dayjs().endOf('month')),
  });

  const fetchCashFlowsData = useCallback(async () => {
    setLoadingCashFlows(true);

    const fetchedCashFlows = await cashFlowService.getCashFlows(cashFlowsFilterAttributes).catch(() => []);
    setCashFlows(fetchedCashFlows);
    setFilteredCashFlows(fetchedCashFlows);

    setLoadingCashFlows(false);
  }, [cashFlowsFilterAttributes]);

  const handleCreateExpense = async (createExpenseAttributes: CreateExpenseAttributes) => {
    await cashFlowService.createExpense(createExpenseAttributes).then(() => {
      setIsCreateExpenseModalOpen(false);
      fetchCashFlowsData();
    }).catch(() => {
      setIsCreateExpenseModalOpen(true);
    });
  };

  const handleRowClick = async (cashFlow: CashFlow) => {
    setIsLoadingUpdateModalOpen(true);

    if (cashFlow.type === CashFlowType.INFLOW) {
      await paymentsService.getPayment(cashFlow.cashFlowId).then((payment) => {
        setSelectedPayment(formHelper.setNullToEmptyStrings(payment));
        setSelectedExpense(null);
        setIsViewPaymentModalOpen(true);
      }).catch(() => setSelectedPayment(null));
    } else {
      await cashFlowService.getExpense(cashFlow.cashFlowId).then((expense) => {
        setSelectedExpense(formHelper.setNullToEmptyStrings(expense));
        setSelectedPayment(null);
        setIsViewExpenseModalOpen(true);
      }).catch(() => setSelectedExpense(null));
    }

    setIsLoadingUpdateModalOpen(false);
  };

  const handleUpdatePayment = async (updatePaymentAttributes: UpdatePaymentAttributes) => {
    await paymentsService.updatePayment(updatePaymentAttributes).then(() => {
      fetchCashFlowsData();
      setIsViewPaymentModalOpen(false);
    }).catch(() => {
      setIsViewPaymentModalOpen(true);
    });
  };

  const handleUpdatePaymentStatus = async (paymentStatus: PaymentStatus) => {
    if (!selectedPayment) return;

    const updatePaymentAttributes: UpdatePaymentAttributes = { paymentId: selectedPayment.paymentId, status: paymentStatus };

    await paymentsService.updatePayment(updatePaymentAttributes).then(() => {
      fetchCashFlowsData();
      setIsViewPaymentModalOpen(false);
    }).catch(() => {
      setIsViewPaymentModalOpen(true);
    });
  };

  const handleUpdateExpense = async (updateExpenseAttributes: UpdateExpenseAttributes) => {
    await cashFlowService.updateExpense(updateExpenseAttributes).then(() => {
      fetchCashFlowsData();
      setIsViewExpenseModalOpen(false);
    }).catch(() => {
      setIsViewExpenseModalOpen(true);
    });
  };

  const closeViewExpenseModal = () => {
    setSelectedExpense(null);
    setIsViewExpenseModalOpen(false);
  };

  const handleDeleteExpense = async (deleteExpenseAttributes: DeleteExpenseAttributes) => {
    await cashFlowService.deleteExpense(deleteExpenseAttributes);
    fetchCashFlowsData();
    closeViewExpenseModal();
  };

  const closeViewPaymentModal = () => {
    setSelectedPayment(null);
    setIsViewPaymentModalOpen(false);
  };

  const handleDeletePayment = async (deletePaymentAttributes: DeletePaymentAttributes) => {
    await paymentsService.deletePayment(deletePaymentAttributes);
    fetchCashFlowsData();
    closeViewPaymentModal();
  };

  useEffect(() => {
    fetchCashFlowsData();
    setSelectedPayment(null);
    setSelectedExpense(null);
  }, [fetchCashFlowsData, cashFlowsFilterAttributes]);

  useEffect(() => {
    const filtered = searchHelper.getFilteredRecordsBySearchTerm<CashFlow>(cashFlows, cashFlowService.partialMatchKeys, cashFlowService.fullMatchKeys, cashFlowSearchTerm);
    setFilteredCashFlows(filtered);
  }, [cashFlows, cashFlowSearchTerm]);

  return (
    <div className="flex flex-col">
      <LoadingModal isOpen={isLoadingUpdateModalOpen} />

      {isCreateExpenseModalOpen && (
        <CreateExpenseModal
          createExpense={handleCreateExpense}
          isOpen={isCreateExpenseModalOpen}
          onClose={() => setIsCreateExpenseModalOpen(false)}
        />
      )}

      {selectedExpense && (
        <ViewExpenseModal
          expense={selectedExpense}
          updateExpense={handleUpdateExpense}
          isOpen={isViewExpenseModalOpen}
          onClose={closeViewExpenseModal}
          deleteExpense={handleDeleteExpense}
        />
      )}

      {selectedPayment && (
        <ViewPaymentModal
          isOpen={isViewPaymentModalOpen}
          payment={selectedPayment}
          updatePaymentStatus={handleUpdatePaymentStatus}
          onClose={() => setIsViewPaymentModalOpen(false)}
          updatePayment={handleUpdatePayment}
          deletePayment={handleDeletePayment}
        />
      )}

      <CashFlowsFilterModal
        isOpen={isCashFlowFilterModalOpen}
        onClose={() => setIsCashFlowFilterModalOpen(false)}
        onCashFlowsFilterAttributesChange={(value) => setCashFlowsFilterAttributes(value)}
        cashFlowsFilterAttributes={cashFlowsFilterAttributes}
      />

      <div className="mb-5">
        <TopLayout
          onOpenCashFlowsFilterModal={() => setIsCashFlowFilterModalOpen(true)}
          setIsCreateExpenselModalOpen={setIsCreateExpenseModalOpen}
          onSearchTermChange={(searchTerm: string) => setCashFlowSearchTerm(searchTerm)}
          onDownloadCashFlowsCsv={cashFlowService.downloadCashFlowsCsv}
        />
      </div>
      <CashFlowTable
        cashFlows={filteredCashFlows}
        loadingCashFlows={loadingCashFlows}
        onSelectRow={(cashFlow) => handleRowClick(cashFlow)}
      />
      <div className="mt-4 inline-flex self-end">
        <CashFlowResume cashFlows={filteredCashFlows} />
      </div>
    </div>
  );
}
