import React from 'react';
import {
  Checkbox, TableBody, TableCell, TableRow,
} from '@mui/material';
import AvatarImg from '../../../../../../shared/components/avatar-img';
import { EmptyRows } from '../../../../../../shared/components';
import tableHelper from '../../../../../../shared/utils/table-helper';
import useTableContext from '../../../../../../context/table/context';
import { Trial } from '../../../../../../models';
import getTaskTypeLabel from '../../../../../../shared/utils/get-task-type-label';
import getTrialSideLabel from '../../../../../../shared/utils/get-trial-side-label';
import trialsTableBodyService from '../../../../../trials/services/trials-table-body-service';
import TrialsBillingStatusLabel from '../trials-billing-status-label/trials-billing-status-label';
import getTrialStatusAttributes from '../../../../../../shared/utils/trial/get-trial-status-attributes';
import dateHelper from '../../../../../../shared/utils/date-helper';
import trialsTableService from '../../services/trials-table-service';
import formatTrialNumber from '../../../../../../shared/utils/trial/format-trial-number';

interface Props {
  trials: Trial[];
  selected: Trial[];
  page: number,
  onTrialClick: (trial: Trial) => void,
}

export default function TrialsTableBody({
  trials, selected, page, onTrialClick,
}: Props) {
  const { defaultRowsPerPage } = useTableContext();
  const effectiveRowsPerPage = defaultRowsPerPage - 1;

  const isSelected = (trial: Trial) => selected.indexOf(trial) !== -1;

  return (
    <TableBody>
      {tableHelper.getTablePageRecords(trials, page, effectiveRowsPerPage)
        .map((trial, index) => {
          const labelId = `trials-table-${index}`;
          const isItemSelected = isSelected(trial);

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={trial.trialId}
              sx={{
                backgroundColor: trialsTableBodyService.isTrialBilled(trial) ? '#ECEFF1' : 'inherit',
              }}
              onClick={() => { onTrialClick(trial); }}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  disabled={trialsTableBodyService.isTrialBilled(trial)}
                />
              </TableCell>
              <TableCell id={labelId} align="left">
                {trial.trialId}
              </TableCell>
              <TableCell align="center">
                <AvatarImg name={trial.user.name} size={30} />
              </TableCell>
              <TableCell align="left">
                {trial.createdAt && dateHelper.getLocaleDate(trial.createdAt)}
              </TableCell>
              <TableCell align="left">
                {dateHelper.getLocaleDate(trial.deadline)}
              </TableCell>
              <TableCell align="center">
                <TrialsBillingStatusLabel status={trial.billingStatus} />
              </TableCell>
              <TableCell align="center">
                <b>{getTrialStatusAttributes(trial.status).text}</b>
              </TableCell>
              <TableCell align="left">
                <p className="text-purple-main font-medium">
                  {tableHelper.getLimitedCellTextContent(getTaskTypeLabel(trial.taskType))}
                </p>
              </TableCell>
              <TableCell align="left">
                {trial.trialNumber && formatTrialNumber(trial.trialNumber.toString())}
              </TableCell>
              <TableCell align="left">
                {trial.plaintiff && tableHelper.getLimitedCellTextContent(trial.plaintiff)}
              </TableCell>
              <TableCell align="left">
                {trial.office?.name && tableHelper.getLimitedCellTextContent(trial.office.name)}
              </TableCell>
              <TableCell align="left">
                {trial.lawyer && tableHelper.getLimitedCellTextContent(trial.lawyer)}
              </TableCell>
              <TableCell align="left">
                {tableHelper.getLimitedCellTextContent(trial.client.name)}
              </TableCell>
              <TableCell align="left">
                <p className="font-bold">
                  {trial.region}
                </p>
              </TableCell>
              <TableCell align="left">
                {trial.trialType}
              </TableCell>
              <TableCell align="left">
                {trial.side && getTrialSideLabel(trial.side)}
              </TableCell>
              <TableCell align="left">
                {trial.defendant && tableHelper.getLimitedCellTextContent(trial.defendant)}
              </TableCell>
              <TableCell align="left">
                {trial.privateAnnotations && tableHelper.getLimitedCellTextContent(trial.privateAnnotations)}
              </TableCell>
              <TableCell align="left">
                {trial.publicAnnotations && tableHelper.getLimitedCellTextContent(trial.publicAnnotations)}
              </TableCell>
            </TableRow>
          );
        })}

      <EmptyRows
        page={page}
        recordsAmount={trials.length}
        columnsAmount={trialsTableService.columnsAmount}
        rowsPerPage={effectiveRowsPerPage}
      />
    </TableBody>
  );
}
