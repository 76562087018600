import axiosInstance from './config/axios';
import GetCashFlowsRequest from './requests/cash-flow/get-cash-flows-request';
import GetCashFlowsResponse from './responses/cash-flow/get-cash-flows-response';

const CASH_FLOW_BASE_URL = '/cash-flow';

const getCashFlows = async (request: GetCashFlowsRequest) => (axiosInstance.get<GetCashFlowsResponse>(CASH_FLOW_BASE_URL, { params: request }));

const getCashFlowsCsv = async () => (axiosInstance.get<string>(`${CASH_FLOW_BASE_URL}/csv`));

export default {
  getCashFlows,
  getCashFlowsCsv,
};
