const images = {
  grLogo: {
    src: '/img/logo.svg',
    alt: 'Gr Pericias Logo',
  },
  airaLogo: {
    src: '/img/aira-logo.svg',
    alt: 'Aira Blue Logo',
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  images,
};
