import React from 'react';
import {
  IconButton, Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { Trial } from '../../../../models';
import { CreatePaymentAttributes } from '../../../../interfaces';
import getTrialTitle from '../../../../shared/utils/trial/get-trial-title';
import AmountTextField from '../../../../shared/components/amount-text-field/amount-text-field';

interface Props {
  trial: Trial;
  paymentTrialIndex: number;
  onDeleteTrial: (trial: Trial) => void;
  isDeleteTrialDisabled: boolean;
}

export default function PaymentTrialInput({
  trial, paymentTrialIndex, onDeleteTrial, isDeleteTrialDisabled,
}: Props) {
  const { control } = useFormContext<CreatePaymentAttributes>();
  return (
    <div className="flex flex-row w-full min-h-18 justify-between">
      <div className="flex flex-row justify-between pb-3 pt-3 pr-5 pl-5 w-8/12 border-[1px] border-solid border-grey-200 rounded-lg">
        <div className="flex flex-col width-full">
          <Typography variant="caption" color="text.secondary">
            ID:
            {' '}
            {trial.trialId}
          </Typography>

          <Typography variant="body1" color="text.secondary">{getTrialTitle(trial)}</Typography>
          <Typography variant="caption" color="text.secondary">{trial.publicAnnotations}</Typography>
        </div>

        <div className="h-full flex items-center">
          <IconButton
            size="small"
            onClick={() => onDeleteTrial(trial)}
            disabled={isDeleteTrialDisabled}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className="w-3/12 flex flex-row justify-start items-end h-full">
        <Controller
          control={control}
          name={`paymentTrials.${paymentTrialIndex}.amount`}
          render={({
            field: {
              value, onChange, ref,
            },
          }) => (
            <AmountTextField
              label="Valor"
              fullWidth
              variant="standard"
              onChange={onChange}
              value={value}
              ref={ref}
            />
          )}
        />

      </div>
    </div>
  );
}
