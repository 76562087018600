import React from 'react';
import { DatailsViewTitle } from '../../../../../shared/components';
import { Payment } from '../../../../../models';
import TrialView from './trial-view';

interface Props {
  payment: Payment;
}

export default function TrialsSection(
  {
    payment,
  }: Props,
) {
  return (
    <div className="w-full flex flex-col">
      <DatailsViewTitle text="Processos" />
      <div className="flex flex-col">
        {payment.paymentTrials.map((paymentTrial, index) => (
          <div className={`w-full ${index !== 0 && 'mt-4'}`} key={paymentTrial.trialId}>
            <TrialView
              paymentTrial={paymentTrial}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
