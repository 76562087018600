import React from 'react';
import { Button } from '@mui/material';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { DelayedSearchBar } from '../../../../../../shared/components';
import TrialsTableToolbar from '../trials-table-toolbar/trials-table-toolbar';
import UpdateTrialBillingStatusAttributes from '../../../../../../interfaces/trials/update-trial-billing-status-attributes';
import { Trial } from '../../../../../../models';

interface Props {
  onSearchTermChange: (searchTerm: string) => void;
  onCreateNewPaymentClick: () => void;
  onOpenTrialsFilterModal: () => void;
  updateTrialBillingStatus: (
    updateTrialBillingStatusAttributes: UpdateTrialBillingStatusAttributes
  ) => void;
  selectedTrials: Trial[];
  unbilledTrials: number;
}

export default function TopLayout({
  onSearchTermChange,
  onCreateNewPaymentClick,
  updateTrialBillingStatus,
  onOpenTrialsFilterModal,
  selectedTrials,
  unbilledTrials,
}: Props) {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row">
        <div className="mr-5">
          <Button
            variant="contained"
            onClick={onCreateNewPaymentClick}
            disabled={selectedTrials.length === 0}
          >
            Novo Pagamento
          </Button>
        </div>
        <div className="mr-5">
          <TrialsTableToolbar
            selectedTrials={selectedTrials}
            updateTrialBillingStatus={updateTrialBillingStatus}
          />
        </div>
      </div>

      <div className="flex h-full flex-row">
        {unbilledTrials > 0 && (
          <div className="flex items-center px-4 mx-2 rounded-full bg-white gap-1">
            <MoneyOffIcon className="font-10" color="primary" />
            <strong>{unbilledTrials}</strong>
          </div>
        )}
        <Button variant="outlined" onClick={onOpenTrialsFilterModal}>
          Filtros
        </Button>
        <div className="ml-5">
          <DelayedSearchBar onSearchTermChange={onSearchTermChange} />
        </div>
      </div>
    </div>
  );
}
