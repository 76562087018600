import React from 'react';
import { Box, Typography } from '@mui/material';
import { Installment } from '../../../../../models';
import getPriceTagFromAmount from '../../../../../shared/utils/payment/get-price-tag-from-amount';
import dateHelper from '../../../../../shared/utils/date-helper';

interface Props {
  installment: Installment;
  installmentIndex: number;
}

export default function InstallmentView({ installment, installmentIndex }: Props) {
  return (
    <div className="flex flex-row w-full min-h-18 items-center justify-between border-[1px] border-solid border-grey-200 rounded-lg pb-3 pt-3 pr-5 pl-5">
      <div className="flex flex-col h-full">
        <Typography variant="caption" color="text.secondary">
          {`Parcela #${installmentIndex + 1}`}
        </Typography>

        <div className="flex flex-row h-full">
          <Typography variant="body1" color="text.secondary">
            <Box component="span" fontWeight="fontWeightMedium" display="inline">{getPriceTagFromAmount(installment.amount)}</Box>
            {` | Prazo: ${dateHelper.getLocaleDate(installment.dueDate)}`}
          </Typography>
        </div>
      </div>

      <Typography variant="h4" color={installment.paid ? 'success.main' : 'error.main'}>
        {installment.paid ? 'Pago' : 'Não Pago'}
      </Typography>
    </div>
  );
}
