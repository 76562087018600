import React from 'react';
import {
  TableBody, TableCell, TableRow,
} from '@mui/material';
import { SortDirection } from '../../../../interfaces';
import { EmptyRows } from '../../../../shared/components';
import tableHelper from '../../../../shared/utils/table-helper';
import useTableContext from '../../../../context/table/context';
import { CashFlow } from '../../../../models';
import DeadlineDate from '../../../trials/components/deadline-date/deadline-date';
import getPaymentMethodLabel from '../../../../shared/utils/payment/get-payment-method-label';
import getPriceTagFromAmount from '../../../../shared/utils/payment/get-price-tag-from-amount';
import cashFlowsTableService from '../../services/cash-flows-table-service';
import CashFlowDescriptionLabel from '../cash-flow-description-label/cash-flow-description-label';
import { CashFlowType, CostCenter } from '../../../../shared/enums';
import getCostCenterLabel from '../../../../shared/utils/cash-flow/get-cost-center-label';

interface Props {
  cashFlows: CashFlow[];
  sortDirection: SortDirection,
  orderByKey: keyof CashFlow;
  page: number,
  onSelectRow: (cashFlow: CashFlow) => void;
}

export default function CashFlowTableBody({
  cashFlows, sortDirection, orderByKey, page, onSelectRow,
}: Props) {
  const { defaultRowsPerPage } = useTableContext();
  const effectiveRowsPerPage = defaultRowsPerPage - 1;

  return (
    <TableBody>
      {tableHelper.getTablePageRecords(cashFlows, page, effectiveRowsPerPage, sortDirection, orderByKey)
        .map((cashFlow, index) => {
          const labelId = `cash-flow-table-${index}`;

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={labelId}
              onClick={() => onSelectRow(cashFlow)}
            >
              <TableCell id={labelId} align="center">
                {cashFlow && <CashFlowDescriptionLabel cashFlow={cashFlow} />}
              </TableCell>
              <TableCell align="center">
                {cashFlow.cashFlowId}
              </TableCell>
              <TableCell align="center">
                <DeadlineDate deadline={cashFlow.transactionDate} isLate={false} />
              </TableCell>
              <TableCell align="center">
                {cashFlow.paymentMethod && getPaymentMethodLabel(cashFlow.paymentMethod)}
              </TableCell>
              <TableCell align="center">
                {cashFlow.type === CashFlowType.OUTFLOW ? getCostCenterLabel(cashFlow.source as CostCenter) : cashFlow.source}
              </TableCell>
              <TableCell align="center">
                {cashFlow.amount && getPriceTagFromAmount(cashFlow.amount)}
              </TableCell>
            </TableRow>
          );
        })}

      <EmptyRows
        page={page}
        recordsAmount={cashFlows.length}
        columnsAmount={cashFlowsTableService.columnsAmount}
        rowsPerPage={effectiveRowsPerPage}
      />
    </TableBody>
  );
}
