enum TrialOrderableColumn {
  TRIAL_ID = 'trialId',
  CLIENT = 'clientName',
  OFFICE = 'officeName',
  RESPONSIBLE = 'responsibleName',
  STATUS = 'orderableStatus',
  BILLING_STATUS = 'billingStatus',
  LAWYER = 'lawyer',
  EMAIL = 'email',
  TASK_TYPE = 'taskType',
  TRIAL_NUMBER = 'trialNumber',
  DEADLINE = 'deadline',
  SIDE = 'side',
  PLAINTIFF = 'plaintiff',
  DEFENDANT = 'defendant',
  PRIVATE_ANNOTATIONS = 'privateAnnotations',
  PUBLIC_ANNOTATIONS = 'publicAnnotations',
  AMOUNT = 'amount',
}

export default TrialOrderableColumn;
