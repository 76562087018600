import FileSaver from 'file-saver';

const saveCsvFile = (rawFileContent: string, name: string) => {
  const file = new Blob([rawFileContent], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(file, `${name}.csv`);
};

const savePdfFile = (rawFileContent: any, name: string) => {
  const url = URL.createObjectURL(rawFileContent);
  const a = document.createElement('a');
  a.download = `${name}.pdf`;
  a.href = url;
  a.target = '_self';

  a.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    a.remove();
    URL.revokeObjectURL(url);
  }, 100);
};

const fileSeverHelper = {
  saveCsvFile,
  savePdfFile,
};

export default fileSeverHelper;
