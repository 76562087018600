import React from 'react';
import { PaymentStatus } from '../../../../shared/enums';
import getPaymentStatusAttributes from '../../../../shared/utils/payment/get-payment-status-attributes';

interface Props {
  status: PaymentStatus;
}

export default function PaymentStatusLabel({ status }: Props) {
  const trialAtributes = getPaymentStatusAttributes(status);

  return (
    <div className={`${trialAtributes.color} w-35 rounded-md`}>
      <p className="p-1">{trialAtributes.text}</p>
    </div>
  );
}
