/* eslint-disable no-bitwise */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import avatarImgService from './services/avatar-img-service';

interface Props {
  name: string | undefined;
  size?: number;
}

const avatarConfig = (name: string | null, size: number) => ({
  sx: {
    bgcolor: name ? avatarImgService.stringToColor(name) : 'gray',
    margin: 'auto',
    width: size,
    height: size,
  },
  children: name ? <span style={{ fontSize: size / 2 }}>{avatarImgService.stringToLetters(name).toUpperCase()}</span> : null,
});

export default function AvatarImg({ name, size = 40 }: Props) {
  return (
    <div className="flex w-full">
      <Tooltip title={name} placement="top">
        <Avatar {...avatarConfig(name || '', size)} />
      </Tooltip>
    </div>
  );
}
