enum Path {
  LOGIN = '/',
  TRIALS = '/processos',
  COMPANIES = '/empresas',
  PAYMENTS = '/pagamentos',
  CASH_FLOWS = '/fluxo-de-caixa',
  USERS = '/usuarios',
}

export default Path;
