import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Autocomplete,
  FormControl,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CreateExpenseAttributes } from '../../../../interfaces';
import { CostCenter, PaymentMethod } from '../../../../shared/enums';
import formHelper from '../../../../shared/utils/form-helper';
import { AmountTextField, DatePickerInput } from '../../../../shared/components';
import getCostCenterLabel from '../../../../shared/utils/cash-flow/get-cost-center-label';
import getPaymentMethodLabel from '../../../../shared/utils/payment/get-payment-method-label';

interface Props {
  createExpense: (createExpenseAttributes: CreateExpenseAttributes) => void;
}

export default function CreateExpenseForm(
  {
    createExpense,
  }: Props,
) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [costCenterAttribute, setCostCenterAttribute] = useState<CostCenter | null>(null);
  const [paymentMethodAttribute, setPaymentMethodAttribute] = useState<PaymentMethod | null>(null);

  const useFormMethods = useForm<CreateExpenseAttributes>({
    mode: 'onTouched',
  });

  const {
    register, handleSubmit, setValue, control, formState: { errors, isValid },
  } = useFormMethods;

  const handleCreateExpenseSubmit = (createExpenseAttributes: CreateExpenseAttributes) => {
    setIsButtonLoading(true);

    createExpense(createExpenseAttributes);

    setIsButtonLoading(false);
  };

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(handleCreateExpenseSubmit)} className="flex flex-col">
        <FormControl variant="standard" fullWidth>
          <TextField
            label="Descrição"
            variant="standard"
            fullWidth
            type="text"
            required
            classes={{ root: 'mb-5' }}
            {...register('description', { maxLength: 80 })}
          />
        </FormControl>

        <div className="flex flex-row mb-5">
          <FormControl variant="standard" className="mr-7" fullWidth>
            <Autocomplete
              id="cost-center-select"
              options={Object.values(CostCenter)}
              getOptionLabel={(option: CostCenter) => getCostCenterLabel(option)}
              value={costCenterAttribute}
              onChange={(_, newValue) => {
                setValue('costCenter', formHelper.getEmptyStringFromUndefined(newValue));
                setCostCenterAttribute(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="cost-center-select"
                  label="Centro de Custo"
                  variant="standard"
                  fullWidth
                  required
                />
              )}
            />
          </FormControl>

          <Controller
            control={control}
            name="amount"
            defaultValue={0}
            render={({
              field: {
                onChange, value, ref,
              },
            }) => (
              <AmountTextField
                label="Valor"
                fullWidth
                variant="standard"
                onChange={onChange}
                value={value}
                ref={ref}
              />
            )}
          />
        </div>
        <div className="flex flex-row mb-5">
          <FormControl variant="standard" className="mr-7" fullWidth>
            <Autocomplete
              id="payment-method-select"
              options={Object.values(PaymentMethod)}
              getOptionLabel={(option: PaymentMethod) => getPaymentMethodLabel(option)}
              value={paymentMethodAttribute}
              onChange={(_, newValue) => {
                setValue('paymentMethod', formHelper.getEmptyStringFromUndefined(newValue));
                setPaymentMethodAttribute(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="payment-method-select"
                  label="Forma de Pagamento"
                  variant="standard"
                  fullWidth
                  required
                />
              )}
            />
          </FormControl>

          <Controller
            control={control}
            name="expenseDate"
            rules={{ required: 'A data da despesa é obrigatória' }}
            render={({
              field: {
                onChange,
                value,
              },
            }) => (
              <DatePickerInput
                onChange={onChange}
                value={value}
                label="Data da Despesa"
                error={!!errors.expenseDate}
                helperText={errors.expenseDate?.message}
                required
              />
            )}
          />
        </div>

        <div className="w-full flex flex-col mb-12">
          <TextField
            label="Observações"
            variant="standard"
            fullWidth
            multiline
            rows={3}
            {...register('notes', {
              maxLength: 80,
              setValueAs: (notes) => (notes.length > 0 ? notes : null),
            })}
          />
        </div>

        <div>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={!isValid}
            loading={isButtonLoading}
          >
            Criar
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
}
