enum PaymentOrderableColumn {
  PAYMENT_ID = 'paymentId',
  COMPANY = 'companyName',
  CREATED_AT = 'createdAt',
  DUE_DATE = 'dueDate',
  PAYMENT_METHOD = 'paymentMethod',
  STATUS = 'orderableStatus',
}

export default PaymentOrderableColumn;
