import React, { useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Payment } from '../../../../models';
import { PaymentDocumentType, PaymentStatus } from '../../../../shared/enums';
import PaymentStatusLabel from '../payment-status-label/payment-status-label';
import ActionsSection from './components/actions-section';
import PaymentAgentSection from './components/payment-agent-section';
import TrialsSection from './components/trials-section';
import GeneralPaymentSection from './components/general-payment-section';
import InstallmentsSection from './components/installments-section';
import NotesSection from './components/notes-section';
import paymentsService from '../payments-tab/services/payments-service';

interface Props {
  payment: Payment;
  updatePaymentStatus: (paymentStatus: PaymentStatus) => void;
}

export default function PaymentDetailsModalContent(
  {
    payment, updatePaymentStatus,
  }: Props,
) {
  const [isSelectPaymentStatusDisabled, setIsSelectPaymentStatusDisabled] = useState(false);

  const handleUpdatePaymentStatus = (trialStatus: PaymentStatus) => {
    setIsSelectPaymentStatusDisabled(true);

    updatePaymentStatus(trialStatus);

    setIsSelectPaymentStatusDisabled(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row mb-10">
        <FormControl variant="standard" classes={{ root: 'w-48 mr-5' }} fullWidth>
          <Select
            labelId="role-select-client"
            id="status-select"
            disableUnderline
            value={payment.status}
            onChange={(event) => handleUpdatePaymentStatus(event.target.value as PaymentStatus)}
            disabled={isSelectPaymentStatusDisabled}
          >
            {Object.values(PaymentStatus).map((value) => (
              <MenuItem
                key={value}
                value={value}
              >
                <PaymentStatusLabel status={value} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="mb-13">
        <ActionsSection
          onReportDownload={() => paymentsService.downloadPaymentDocument({ paymentId: payment.paymentId, documentType: PaymentDocumentType.REPORT, payment })}
          onReceiptDownload={(showDueDate) => {
            const documentType = showDueDate ? PaymentDocumentType.RECEIPT : PaymentDocumentType.RECEIPT_WITH_NO_DUE_DATE;
            paymentsService.downloadPaymentDocument({ paymentId: payment.paymentId, documentType, payment });
          }}
          onCsvDownload={() => paymentsService.downloadPaymentTrialsCsv(payment.paymentId)}
        />
      </div>

      <div className="mb-13">
        <PaymentAgentSection payment={payment} />
      </div>

      <div className="mb-13">
        <GeneralPaymentSection payment={payment} />
      </div>

      <div className="mb-13">
        <TrialsSection payment={payment} />
      </div>

      <div className={payment.notes ? 'mb-13' : undefined}>
        <InstallmentsSection payment={payment} />
      </div>

      {payment.notes && (
        <div>
          <NotesSection notes={payment.notes} />
        </div>
      )}
    </div>
  );
}
