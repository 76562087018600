import axiosInstance from './config/axios';
import CreateExpenseRequest from './requests/expense/create-expense-request';
import DeleteExpenseRequest from './requests/expense/delete-expense-request';
import UpdateExpenseRequest from './requests/expense/update-expense-request';
import CreateExpenseResponse from './responses/expense/create-expense-response';
import GetExpenseResponse from './responses/expense/get-expense-response';
import UpdateExpenseResponse from './responses/expense/update-expense-response';

const EXPENSE_BASE_URL = '/expense';

const createExpense = async (request: CreateExpenseRequest) => (axiosInstance.post<CreateExpenseResponse>(EXPENSE_BASE_URL, request));

const updateExpense = async (request: UpdateExpenseRequest) => (axiosInstance.put<UpdateExpenseResponse>(`${EXPENSE_BASE_URL}/${request.expenseId}`, request));

const getExpense = async (expenseId: number) => (axiosInstance.get<GetExpenseResponse>(`${EXPENSE_BASE_URL}/${expenseId}`));

const deleteExpense = async (request: DeleteExpenseRequest) => (axiosInstance.delete<void>(`${EXPENSE_BASE_URL}/${request.expenseId}`));

export default {
  createExpense,
  updateExpense,
  getExpense,
  deleteExpense,
};
